import { faSave } from '@fortawesome/pro-light-svg-icons';
import { arrayMoveImmutable } from 'array-move';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useClient } from '../../../../api';
import { PackingPreset } from '../../../../api/types';
import Button from '../../../../components/Input/Button';
import Container from '../../../../components/Layout/Container';
import Form from './components/Form';
import PresetRow from './components/PresetRow';

export const PackagingPresetsView = () => {
  const {
    getPackingPresets,
    deletePackingPreset,
    updatePackingPresetOrder,
    setPackingPresetDefault,
  } = useClient();

  const { data, refetch } = useQuery(['packing-presets'], getPackingPresets);

  const [sorted, setSorted] = useState<readonly PackingPreset[]>([]);

  const {
    mutate: updatePackingPresetOrderMutation,
    isLoading: updatePackingPresetOrderIsLoading,
  } = useMutation(updatePackingPresetOrder, {
    onSuccess: () => refetch(),
  });

  const {
    mutate: setPackingPresetDefaultMutation,
    isLoading: setPackingPresetDefaultIsLoading,
  } = useMutation(setPackingPresetDefault, {
    onMutate: () =>
      updatePackingPresetOrder({ ids: sorted.map((item) => item.id) }),
    onSuccess: () => refetch(),
  });

  const {
    mutate: deletePackingPresetMutation,
    isLoading: deletePackingPresetIsLoading,
  } = useMutation(deletePackingPreset, {
    onSuccess: () => refetch(),
  });

  const handleMove = (preset: PackingPreset, direction: 1 | -1) => {
    const index = sorted.indexOf(preset);

    setSorted(
      arrayMoveImmutable(
        sorted,
        index,
        index + direction > sorted.length - 1 ? 0 : index + direction,
      ),
    );
  };

  useEffect(
    () =>
      setSorted(
        data
          ? data
              .slice()
              .sort((a, b) => a.order - b.order)
              .map((preset, order) => ({ ...preset, order }))
          : [],
      ),
    [data],
  );

  return (
    <div className="flex h-full">
      <div className="flex-grow overflow-auto p-5">
        <Container>
          <div className="p-5 grid gap-5">
            {data && (
              <div>
                <table className="w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-left">Description</th>
                      <th className="text-left">Length</th>
                      <th className="text-left">Width</th>
                      <th className="text-left">Height</th>
                      <th className="text-left">Weight</th>
                      <th className="text-left">Order</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {sorted.map((preset) => (
                      <PresetRow
                        key={preset.id}
                        preset={preset}
                        onMoveUp={() => handleMove(preset, -1)}
                        onMoveDown={() => handleMove(preset, 1)}
                        onDelete={() =>
                          deletePackingPresetMutation({ id: preset.id })
                        }
                        onToggleDefault={() =>
                          setPackingPresetDefaultMutation({ id: preset.id })
                        }
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <div>
              <Button
                loading={updatePackingPresetOrderIsLoading}
                onClick={() =>
                  updatePackingPresetOrderMutation({
                    ids: sorted.map((item) => item.id),
                  })
                }
                icon={faSave}
              >
                Save Order
              </Button>
            </div>
          </div>
        </Container>
      </div>
      <div className="p-5 pl-0 flex-shrink-0 w-96">
        <Container>
          <div className="p-5">
            <h3 className="font-semibold text-ui-800 mb-5">New</h3>

            <Form onUpdate={() => refetch()} />
          </div>
        </Container>
      </div>
    </div>
  );
};
