import { Report } from '../../../api/types';
import Row from './Row';

type Props = {
  readonly lines: readonly Report[];
};

const Budgets = ({ lines }: Props) => {
  return (
    <>
      {lines.map((line) => (
        <Row
          key={line.source}
          source={line.source}
          values={[
            { value: line.monthBudget, money: true },
            {
              value: line.monthActual,
              money: true,
              sentiment: line.monthActual >= line.monthBudget ? 'good' : 'bad',
            },
            { value: line.yearBudget, money: true },
            {
              value: line.yearActual,
              money: true,
              sentiment: line.yearActual >= line.yearBudget ? 'good' : 'bad',
            },
          ]}
        />
      ))}
    </>
  );
};

export default Budgets;
