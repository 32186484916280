import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

type Props = {
  readonly label: string;
  readonly value: boolean;
  readonly onChange: (value: boolean) => void;
};

const theme = ({ value }: Partial<Props>) =>
  classNames('flex w-full h-5 text-left mb-1 text-ui-400 last:mb-0', {
    'text-ui-900 font-medium': value,
    'hover:text-ui-600': !value,
  });

const checkboxTheme = ({ value }: Partial<Props>) =>
  classNames(
    'w-5 h-5 leading-5 text-center text-xs bg-ui-200 self-center rounded',
    {
      'bg-ui-600 text-white': value,
    },
  );

const Line = ({ label, value, onChange }: Props) => {
  return (
    <button
      onClick={(event) => {
        event.preventDefault();
        onChange(!value);
      }}
      className={theme({ value })}
    >
      <span className={checkboxTheme({ value })}>
        <FontAwesomeIcon icon={faCheck} />
      </span>{' '}
      <span className="flex-grow self-center pl-2 text-sm">{label}</span>
    </button>
  );
};

export default Line;
