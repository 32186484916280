import { useMemo, useState } from 'react';
import { ServerError } from '../../../../../../api/error';
import {
  OrderShipment,
  OrderShipmentProvider,
  PackingPreset,
  User,
} from '../../../../../../api/types';
import Heading from '../../components/Heading';
import SplitPanel from '../../components/SplitPanel';
import Form from './components/Form';
import Shipment from './components/Shipment';

type Props = {
  readonly orderId: number;
  readonly onUpdate: () => Promise<any>;
  readonly onError: (error: ServerError | null) => void;
  readonly customerReference?: string;
  readonly shippingFirstName?: string;
  readonly shippingLastName?: string;
  readonly businessName?: string;
  readonly customerEmail?: string;
  readonly customerPhone?: string;
  readonly addressLine1?: string;
  readonly addressLine2?: string;
  readonly suburb?: string;
  readonly state?: string;
  readonly postcode?: string;
  readonly shippingNotes?: string;
  readonly isExpressShipping: boolean;
  readonly shipments: readonly OrderShipment[];
  readonly providers: readonly OrderShipmentProvider[];
  readonly packingPresets: readonly PackingPreset[];
  readonly locations: readonly string[];
  readonly permissions: User['permissions'];
};

export const ShipmentView = ({
  orderId,
  onUpdate,
  onError,
  customerReference,
  shippingFirstName,
  shippingLastName,
  businessName,
  customerEmail,
  customerPhone,
  addressLine1,
  addressLine2,
  suburb,
  state,
  postcode,
  shippingNotes,
  isExpressShipping,
  packingPresets,
  providers,
  shipments,
  locations,
  permissions,
}: Props) => {
  const [showForm, setShowForm] = useState(true);

  const filteredProviders = useMemo(() => {
    return providers.filter(
      (provider) =>
        !provider.location || locations.includes(provider.location.handle),
    );
  }, [providers, locations]);

  const handleShipmentCreated = async () => {
    await onUpdate();

    setShowForm(false);
  };

  return (
    <SplitPanel>
      {shipments.length > 0 && (
        <div className="pb-10 mb-10 border-b borer-slate-100">
          <Heading text="Existing Shipments" />

          <div className="space-y-5">
            {shipments.map((shipment) => (
              <Shipment shipment={shipment} />
            ))}
          </div>
        </div>
      )}

      {showForm && (
        <Form
          orderId={orderId}
          onShipmentCreated={handleShipmentCreated}
          onError={onError}
          defaultCustomerReference={customerReference}
          defaultShippingFirstName={shippingFirstName}
          defaultShippingLastName={shippingLastName}
          defaultBusinessName={businessName}
          defaultCustomerEmail={customerEmail}
          defaultCustomerPhone={customerPhone}
          defaultAddressLine1={addressLine1}
          defaultAddressLine2={addressLine2}
          defaultSuburb={suburb}
          defaultState={state}
          defaultPostcode={postcode}
          defaultShippingNotes={shippingNotes}
          isExpressShipping={isExpressShipping}
          providers={filteredProviders}
          packingPresets={packingPresets}
        />
      )}
    </SplitPanel>
  );
};
