import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import type { Order } from '../../../../api/types';
import Row from './components/Row';

type Props = {
  readonly orders: readonly Order[];
  readonly selectedOrderIds: readonly number[];
  readonly selectableOrderIds: readonly number[];
  readonly onInspect: (id: number) => void;
  readonly onSelect: (id: number) => void;
  readonly onDeselect: (id: number) => void;
  readonly onViewNotes: (id: number) => void;
  readonly onViewStatus: (id: number) => void;
};

type CellHeaderProps = {
  readonly children: ReactNode;
};

const CellHeader = ({ children }: CellHeaderProps) => {
  return <th className="p-5 font-bold text-left text-sm">{children}</th>;
};

export const OrderList = ({
  orders,
  selectedOrderIds,
  selectableOrderIds,
  onInspect,
  onSelect,
  onDeselect,
  onViewNotes,
  onViewStatus,
}: Props) => {
  if (orders.length === 0) {
    return (
      <div className="w-full h-full flex">
        <div className="self-center flex w-96 mx-auto">
          <div className="text-4xl mb-5 text-ui-200 mr-10">
            <FontAwesomeIcon icon={faExclamationCircle} />
          </div>
          <div className="flex-grow">
            <h6 className="font-bold text-lg mb-2">No Results</h6>
            <p className="text-ui-600">
              The criteria you have selected does not match any orders. Try
              different criteria or refresh the page.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <table className="w-full whitespace-nowrap text-sm">
        <thead>
          <tr>
            <th colSpan={3} />
            <CellHeader>Order #</CellHeader>
            <CellHeader>Axapta #</CellHeader>
            <th />
            <CellHeader>Billing Name</CellHeader>
            <CellHeader>Order Date</CellHeader>
            <CellHeader>Last Updated</CellHeader>
            <th className="p-5 font-bold text-right text-sm">Value</th>
            <CellHeader>Status</CellHeader>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => {
            const [lastStatusChange] = order.statusChanges;
            const { status, created: lastUpdated } = lastStatusChange;

            const billingName = [
              order.billingAddress?.firstName,
              order.billingAddress?.lastName,
            ]
              .join(' ')
              .trim();

            return (
              <Row
                key={order.id}
                id={order.sourceReference}
                priority={order.priority}
                source={order.source.handle}
                axaptaOrderId={order.axaptaOrderId}
                axaptaOrderIdReceivedAt={order.axaptaOrderIdReceivedAt}
                billingName={billingName}
                status={status.handle}
                reason={lastStatusChange.reason?.description}
                orderDate={order.created}
                lastUpdated={lastUpdated}
                selected={selectedOrderIds.includes(order.id)}
                selectable={selectableOrderIds.includes(order.id)}
                value={order.total}
                noteCount={order.notes.length}
                onInspect={() => onInspect(order.id)}
                onSelect={() => onSelect(order.id)}
                onDeselect={() => onDeselect(order.id)}
                onViewNotes={() => onViewNotes(order.id)}
                onViewStatus={() => onViewStatus(order.id)}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
