type Props = {
  readonly source: string;
};

const SourceImage = ({ source }: Props) => {
  return (
    <img
      className="h-6"
      src={`https://storage.alessiaustralia.com.au/omd/${source}.png`}
      alt=""
    />
  );
};

export default SourceImage;
