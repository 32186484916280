import { AxiosInstance } from 'axios';
import { QueryFunctionContext } from 'react-query';
import { Release } from '../types';

export function getReleases(client: AxiosInstance) {
  return async ({
    queryKey: [_],
  }: QueryFunctionContext<[string]>): Promise<readonly Release[]> => {
    const { data } = await client.get<readonly Release[]>(`releases`);

    return data;
  };
}
