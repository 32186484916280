import { AxiosInstance } from 'axios';
import { CreateShipmentModel, OrderShipment } from '../types';

type Variables = {
  readonly id: number;
  readonly model: CreateShipmentModel;
};

export function createShipment(client: AxiosInstance) {
  return async (variables: Variables) => {
    const { data } = await client.post<OrderShipment>(
      `orders/${variables.id}/ship`,
      variables.model,
    );

    return data;
  };
}
