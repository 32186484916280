import { AxiosInstance } from 'axios';
import { OrderReturn, OrderReturnType } from '../types';

type Variables = {
  readonly returnId: number;
  readonly type: OrderReturnType['handle'];
};

export function completeReturn(client: AxiosInstance) {
  return async (variables: Variables) => {
    const { data } = await client.patch<OrderReturn>(
      `returns/${variables.returnId}`,
      { type: variables.type },
    );

    return data;
  };
}
