import { ReactNode } from 'react';

type Props = {
  readonly children: ReactNode;
  readonly label: string;
};

const Line = ({ label, children }: Props) => {
  return (
    <div className="grid grid-cols-5">
      <div className="text-ui-600 col-span-2">{label}</div>
      <div className="flex items-center col-span-3">{children}</div>
    </div>
  );
};

export default Line;
