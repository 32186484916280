type Props = {
  readonly type?: 'email' | 'text' | 'password';
  readonly placeholder?: string;
  readonly value: string;
  readonly onChange: (value: string) => void;
};

const TextInput = ({ type = 'text', placeholder, value, onChange }: Props) => {
  return (
    <input
      className="block w-full bg-white border border-ui-400 shadow-sm rounded px-3 py-2 focus:outline-none focus:border-ui-500"
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(event) => onChange(event.target.value)}
    />
  );
};

export default TextInput;
