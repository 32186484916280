import { faFileInvoice } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { OrderShipment } from '../../../../../../../api/types';
import Button from '../../../../../../../components/Input/Button';
import InlineUser from '../../../../../../../components/Misc/InlineUser';
import { formatMoney } from '../../../../../../../formatting/formatMoney';

type Props = {
  readonly shipment: OrderShipment;
};

const Shipment = ({ shipment }: Props) => {
  const formattedDate = format(new Date(shipment.created), 'do MMM yyyy h:mma');

  return (
    <article className="border border-ui-300 p-2 rounded">
      <div className="grid grid-cols-5 gap-1 mb-2">
        <div className="bg-ui-100 rounded p-5">
          <h6 className="text-sm text-ui-500">Shipped By</h6>
          {shipment.user ? <InlineUser user={shipment.user} /> : 'Automated'}
        </div>
        <div className="bg-ui-100 rounded p-5">
          <h6 className="text-sm text-ui-500">Shipped At</h6>
          {formattedDate}
        </div>
        <div className="bg-ui-100 rounded p-5">
          <h6 className="text-sm text-ui-500">Method</h6>
          {shipment.product?.provider?.name ?? '-'}
          <br />
          {shipment.product?.name ?? '-'}
        </div>
        <div className="bg-ui-100 rounded p-5">
          <h6 className="text-sm text-ui-500">Consignment ID</h6>
          {shipment.consignmentId}
        </div>
        <div className="bg-ui-100 rounded p-5">
          <h6 className="text-sm text-ui-500">Cost</h6>
          {shipment.cost ? formatMoney(shipment.cost) : '-'}
        </div>
      </div>

      <div>
        {shipment.labelUrl ? (
          <Button href={shipment.labelUrl}>
            <FontAwesomeIcon icon={faFileInvoice} /> Download Label
          </Button>
        ) : (
          <p className="text-ui-300">No label available.</p>
        )}
      </div>
    </article>
  );
};

export default Shipment;
