import { AxiosInstance } from 'axios';

type Variables = {
  readonly id: number;
  readonly model: {
    readonly addressLine1: string;
    readonly addressLine2: string;
    readonly suburb: string;
    readonly state: string;
    readonly postcode: string;
  };
};

export function updateAddress(client: AxiosInstance) {
  return async (variables: Variables) => {
    const { data } = await client.patch<void>(
      `addresses/${variables.id}`,
      variables.model,
    );

    return data;
  };
}
