import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { useClient } from '../../api';
import { OrderSourceHandle, OrderStatusHandle } from '../../api/enums';
import {
  ApplicationMetadata,
  SearchOrdersCriteria,
  User,
} from '../../api/types';
import Checkbox from '../../components/Input/Checkbox';
import ControlPanel from '../../components/Layout/ControlPanel';
import Pagination from '../../components/Layout/Pagination';
import { canPrintOrder } from '../../support';
import OrderFilters from './components/OrderFilters';
import OrderList from './components/OrderList';
import OrderListOverview from './components/OrderListOverview';
import OrderPanel from './components/OrderPanel';
import PrintControls from './components/PrintControls';

type Props = {
  readonly metadata: ApplicationMetadata;
  readonly permissions: User['permissions'];
};

export const OrdersView = ({ metadata, permissions }: Props) => {
  const { id } = useParams<{ readonly id: string }>();

  const history = useHistory();

  const [criteria, setCriteria] = useState<Partial<SearchOrdersCriteria>>({
    offset: 0,
    limit: 100,
    orderBy: 'priority',
    sources: Object.values(OrderSourceHandle).filter(
      (source) => source !== 'sandbox',
    ),
    statuses: [
      OrderStatusHandle.New,
      OrderStatusHandle.Ready,
      OrderStatusHandle.Pending,
      OrderStatusHandle.Printed,
    ],
    locations: [],
    flags: [],
  });

  const { getOrders } = useClient();
  const { data, refetch, isFetching } = useQuery(
    ['orders', criteria],
    getOrders,
    {
      refetchInterval: 30000,
      keepPreviousData: true,
    },
  );

  const [selectedOrderIds, setSelectedOrderIds] = useState<readonly number[]>(
    [],
  );

  const [selectableOrderIds, setSelectableOrderIds] = useState<
    readonly number[]
  >([]);

  const handleToggleAll = () => {
    if (data) {
      if (selectedOrderIds.length === selectableOrderIds.length) {
        setSelectedOrderIds([]);
      } else {
        setSelectedOrderIds(
          data.orders
            .filter((order) =>
              canPrintOrder(order.axaptaOrderId, order.source.handle),
            )
            .map((order) => order.id),
        );
      }
    }
  };

  const handleInspect = (id: number) => history.push(`/orders/${id}`);
  const handleClose = () => history.push(`/orders`);

  useEffect(() => {
    if (data) {
      const selectable = data.orders
        .filter((order) =>
          canPrintOrder(order.axaptaOrderId, order.source.handle),
        )
        .map((order) => order.id);

      const validOrderIds = selectable.map((id) => id);

      // Determine orders that can actually be bulk selected.
      setSelectableOrderIds(selectable);

      // Auto deselect orders that are no longer in view.
      setSelectedOrderIds(
        selectedOrderIds.filter((id) => validOrderIds.includes(id)),
      );
    }
  }, [data]);

  return (
    <>
      <Helmet>
        <title>Orders &middot; OMD</title>
      </Helmet>
      <div className="flex">
        <ControlPanel>
          <OrderFilters
            criteria={criteria}
            onChange={(criteria) => setCriteria(criteria)}
            statuses={metadata.statuses.map((status) => ({
              label: status.name,
              value: status.handle,
            }))}
            locations={metadata.locations.map((location) => ({
              label: location.name,
              value: location.handle,
            }))}
            sources={metadata.sources.map((source) => ({
              label: source.name,
              value: source.handle,
            }))}
            permissions={permissions}
          />
        </ControlPanel>

        <section className="h-screen flex flex-col flex-grow overflow-hidden">
          <section>
            <div className="p-5">
              <OrderListOverview
                criteria={criteria}
                onChange={(value) => setCriteria({ ...criteria, ...value })}
              />
            </div>
          </section>

          <section className="flex-grow border-t border-b border-ui-300 overflow-hidden">
            <div className="flex h-full">
              <div className="flex-grow overflow-auto h-full">
                {data ? (
                  <OrderList
                    orders={data.orders}
                    selectableOrderIds={selectableOrderIds}
                    selectedOrderIds={selectedOrderIds}
                    onInspect={handleInspect}
                    onSelect={(id) =>
                      setSelectedOrderIds(selectedOrderIds.concat(id))
                    }
                    onDeselect={(id) =>
                      setSelectedOrderIds(
                        selectedOrderIds.filter(
                          (selectedId) => id !== selectedId,
                        ),
                      )
                    }
                    onViewNotes={(id) => history.push(`/orders/${id}/notes`)}
                    onViewStatus={(id) => history.push(`/orders/${id}/status`)}
                  />
                ) : (
                  <div className="flex h-full items-center">
                    <div className="text-center w-full">
                      <p>Loading Orders&hellip;</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>

          {data && (
            <section className="py-5">
              <div className="grid grid-cols-5 gap-5 px-5">
                <div className="col-span-3">
                  {permissions.includes('print:orders') && (
                    <>
                      <Checkbox
                        value={selectedOrderIds.length === data.orders.length}
                        onChange={() => handleToggleAll()}
                      />
                      <span className="inline-block pl-4 w-48">
                        {selectedOrderIds.length} / {selectableOrderIds.length}{' '}
                        selected
                      </span>

                      <PrintControls
                        orders={data.orders}
                        selectedOrderIds={selectedOrderIds}
                        selectableOrderIds={selectableOrderIds}
                        onUpdateStatuses={() => refetch()}
                      />
                    </>
                  )}
                </div>
                <div className="col-span-2 text-right">
                  <Pagination
                    offset={data.offset}
                    limit={data.limit}
                    total={data.total}
                    onChange={(offset) => {
                      console.log(offset);
                      setCriteria({ ...criteria, offset });
                    }}
                  />
                </div>
              </div>
            </section>
          )}
        </section>

        {id && (
          <OrderPanel
            metadata={metadata}
            permissions={permissions}
            id={parseInt(id)}
            onClose={() => handleClose()}
          />
        )}
      </div>
    </>
  );
};
