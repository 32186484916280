import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';

type Props = {
  readonly orderId: number;
  readonly text: string;
  readonly icon: IconProp;
  readonly path: string;
  readonly counter?: number;
  readonly done?: boolean;
};

const NavigationItem = ({
  orderId,
  text,
  icon,
  path,
  counter,
  done,
}: Props) => {
  return (
    <div className="text-ui-500">
      <NavLink
        exact
        className="flex items-center hover:text-ui-600 px-5 py-2"
        activeClassName="flex hover:text-ui-900 bg-ui-200 text-ui-900"
        to={`/orders/${orderId}` + (path ? '/' + path : '')}
      >
        <span className="w-8 text-center pr-4 text-ui-500">
          <FontAwesomeIcon icon={icon} />
        </span>
        <span className="flex-grow text-sm font-semibold">{text}</span>
        {counter !== undefined && (
          <span className="flex-shrink-0 self-center inline-block w-5 h-5 bg-red-500 text-white leading-5 text-center rounded text-xs">
            {counter}
          </span>
        )}
        {done && (
          <span className="flex-shrink-0 self-center inline-block w-5 h-5 bg-green-500 text-white leading-5 text-center rounded text-xs">
            <FontAwesomeIcon icon={faCheck} />
          </span>
        )}
      </NavLink>
    </div>
  );
};

export default NavigationItem;
