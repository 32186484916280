import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

type Props = {
  readonly text: string;
  readonly to: string;
  readonly icon: IconProp;
  readonly expanded: boolean;
};

const theme = (expanded: boolean) =>
  classNames('block py-2 hover:bg-ui-800', {
    'px-5': expanded,
    'text-center': !expanded,
  });

const iconTheme = (expanded: boolean) =>
  classNames('inline-block text-center text-sm', {
    'w-8 mr-2': expanded,
  });

const NavigationItem = ({ text, to, icon, expanded }: Props) => {
  return (
    <div className="text-ui-500">
      <NavLink
        to={to}
        className={theme(expanded)}
        activeClassName="bg-ui-800 text-white"
      >
        <span className={iconTheme(expanded)}>
          <FontAwesomeIcon icon={icon} />
        </span>
        {expanded && <>{text}</>}
      </NavLink>
    </div>
  );
};

export default NavigationItem;
