export function formatDuration(value: number): string {
  let seconds = value / 1000;

  let days = 0;
  let hours = 0;
  let minutes = 0;

  while (seconds >= 3600 * 24) {
    seconds -= 3600 * 24;
    days++;
  }

  while (seconds >= 3600) {
    seconds -= 3600;
    hours++;
  }

  while (seconds >= 60) {
    seconds -= 60;
    minutes++;
  }

  return value
    ? `${days} day${days === 1 ? '' : 's'}, ${hours} hour${
        hours === 1 ? '' : 's'
      }, ${minutes} minute${minutes === 1 ? '' : 's'}`
    : '-';
}
