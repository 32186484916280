import {
  faSortAmountDown,
  faSortAmountDownAlt,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo, useState } from 'react';
import { OrderSourceHandle, OrderStatusHandle } from '../../../../api/enums';
import { OrderFlag, SearchOrdersCriteria, User } from '../../../../api/types';
import DateTime from '../../../../components/Input/DateTime';
import Select from '../../../../components/Input/Select';
import Group from './components/Group';
import Line from './components/Line';

type Option = {
  readonly label: string;
  readonly value: string;
};

type Props = {
  readonly criteria: Partial<SearchOrdersCriteria>;
  readonly statuses: readonly Option[];
  readonly sources: readonly Option[];
  readonly locations: readonly Option[];
  readonly permissions: User['permissions'];
  readonly onChange: (criteria: Partial<SearchOrdersCriteria>) => void;
};

export const OrderFilters = ({
  criteria,
  statuses,
  sources,
  locations,
  permissions,
  onChange,
}: Props) => {
  const [start, setStart] = useState<string>('');
  const [end, setEnd] = useState<string>('');

  const ascending = !criteria.order || criteria.order.toLowerCase() === 'asc';

  // If no specific locations are associated with a user, allow them to specify
  // locations to load orders from.
  const allowLocationSelection = useMemo(() => {
    return (
      !permissions.includes('read:orders:huntingwood') &&
      !permissions.includes('read:orders:frenchs-forest')
    );
  }, [permissions]);

  const handleToggleSource = (source: OrderSourceHandle, value: boolean) => {
    const current = criteria.sources ?? [];

    onChange({
      ...criteria,
      sources: value
        ? current.concat(source)
        : current.filter((entry) => entry !== source),
    });
  };

  const handleToggleStatus = (status: OrderStatusHandle, value: boolean) => {
    const current = criteria.statuses ?? [];

    onChange({
      ...criteria,
      statuses: value
        ? current.concat(status)
        : current.filter((entry) => entry !== status),
    });
  };

  const handleToggleLocation = (location: string, value: boolean) => {
    if (value) {
      onChange({
        ...criteria,
        locations: (criteria.locations ?? []).concat(location),
        // Remove combos flag when picking a location.
        flags: (criteria.flags ?? []).filter((flag) => flag !== 'COMBOS'),
      });
    } else {
      onChange({
        ...criteria,
        locations: (criteria.locations ?? []).filter(
          (entry) => entry !== location,
        ),
      });
    }
  };

  const addFlag = (value: OrderFlag) =>
    onChange({
      ...criteria,
      flags: (criteria.flags ?? []).concat(value),
    });

  const removeFlag = (value: OrderFlag) =>
    onChange({
      ...criteria,
      flags: (criteria.flags ?? []).filter((flag) => flag !== value),
    });

  useEffect(() => {
    onChange({
      ...criteria,
      startUnix: start ? new Date(start).getTime() : undefined,
      endUnix: end ? new Date(end).getTime() : undefined,
    });
  }, [start, end]);

  return (
    <div className="h-screen overflow-auto">
      <Group heading="Date Range">
        <div className="mb-2">
          <DateTime value={start} onChange={(value) => setStart(value)} />
        </div>
        <div>
          <DateTime value={end} onChange={(value) => setEnd(value)} />
        </div>
      </Group>

      <Group heading="Source">
        {sources.map((source) => (
          <Line
            key={source.value}
            value={
              criteria.sources
                ? criteria.sources.includes(source.value as any)
                : false
            }
            onChange={(value) =>
              handleToggleSource(source.value as OrderSourceHandle, value)
            }
            label={source.label}
          />
        ))}
      </Group>

      <Group heading="Status">
        <Line
          value={criteria.statuses?.length === statuses.length}
          onChange={(value) => {
            onChange({
              ...criteria,
              statuses: value ? statuses.map((status) => status.value) : [],
            });
          }}
          label="All"
        />

        {statuses.map((status) => (
          <Line
            key={status.value}
            value={
              criteria.statuses
                ? criteria.statuses.includes(status.value as any)
                : false
            }
            onChange={(value) =>
              handleToggleStatus(status.value as OrderStatusHandle, value)
            }
            label={status.label}
          />
        ))}
      </Group>

      <Group heading="Products">
        <Line
          value={criteria.productSkuPrefix === undefined}
          onChange={() =>
            onChange({ ...criteria, productSkuPrefix: undefined })
          }
          label="All Products"
        />
        <Line
          value={criteria.productSkuPrefix === '10019908'}
          onChange={() =>
            onChange({ ...criteria, productSkuPrefix: '10019908' })
          }
          label="Gift Cards"
        />
        <Line
          value={criteria.productSkuPrefix === 'DEL'}
          onChange={() => onChange({ ...criteria, productSkuPrefix: 'DEL' })}
          label="Loyalty Gifts"
        />
        <Line
          value={criteria.flags?.includes('SEEK_AND_SECURE') ?? false}
          onChange={(value) => {
            value ? addFlag('SEEK_AND_SECURE') : removeFlag('SEEK_AND_SECURE');
          }}
          label="Seek & Secure"
        />
      </Group>

      <Group heading="Location">
        <Line
          value={criteria.flags?.includes('COMBOS') ?? false}
          onChange={(value) => {
            onChange({
              ...criteria,
              flags: value
                ? (criteria.flags ?? []).concat('COMBOS')
                : (criteria.flags ?? []).filter((flag) => flag !== 'COMBOS'),
              locations: value ? [] : criteria.locations,
            });
          }}
          label="All Locations (Combos)"
        />
        {allowLocationSelection &&
          locations.map((location) => (
            <Line
              key={location.value}
              value={
                criteria.locations
                  ? criteria.locations.includes(location.value as any)
                  : false
              }
              onChange={(value) => handleToggleLocation(location.value, value)}
              label={location.label}
            />
          ))}
      </Group>

      <Group heading="Sorting">
        <div className="flex">
          <div className="flex-grow self-center">
            <Select
              value={criteria.orderBy ?? 'priority'}
              options={[
                { label: 'Priority', value: 'priority' },
                { label: 'Order Date', value: 'created' },
                { label: 'Last Updated', value: 'updated' },
                { label: 'Value', value: 'total' },
              ]}
              onChange={(value) =>
                onChange({ ...criteria, orderBy: value as any })
              }
            />
          </div>
          <div className="flex-shrink-0 self-center pl-2">
            <button
              title={ascending ? 'Sort descending' : 'Sort ascending'}
              onClick={(event) => {
                event.preventDefault();
                onChange({ ...criteria, order: ascending ? 'DESC' : 'ASC' });
              }}
              className="bg-white border border-ui-200 h-8 w-8 leading-8 text-center text-ui-600 hover:text-ui-900 rounded"
            >
              <FontAwesomeIcon
                icon={ascending ? faSortAmountDownAlt : faSortAmountDown}
              />
            </button>
          </div>
        </div>
      </Group>
    </div>
  );
};
