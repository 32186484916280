import { useState } from 'react';
import { useMutation } from 'react-query';
import { useClient } from '../../../../../api';
import { SKUResponse } from '../../../../../api/queries/searchSkus';
import Button from '../../../../../components/Input/Button';
import Select from '../../../../../components/Input/Select';
import TextInput from '../../../../../components/Input/TextInput';

type Props = {
  readonly value: SKUResponse;
  readonly onSave: () => void;
};

export default function LineItem({ value, onSave }: Props) {
  const [binLocation, setBinLocation] = useState(value.binLocation ?? '');
  const [location, setLocation] = useState(value.location?.handle ?? '');
  const [barcode, setBarcode] = useState(value.barcode ?? '');

  const { upsertSku } = useClient();

  const { mutate, isLoading } = useMutation(upsertSku, {
    onSuccess: () => onSave(),
  });

  return (
    <tr>
      <td className="p-1">{value.sku}</td>
      <td className="p-1">
        <TextInput
          placeholder="Bin Location"
          value={binLocation}
          onChange={setBinLocation}
        />
      </td>
      <td className="p-1">
        <Select
          value={location}
          onChange={setLocation}
          options={[
            {
              value: 'frenchs-forest',
              label: 'Frenchs Forest',
            },
            {
              value: 'huntingwood',
              label: 'Huntingwood',
            },
          ]}
        />
      </td>
      <td className="p-1">
        <TextInput
          placeholder="Barcode"
          value={barcode}
          onChange={setBarcode}
        />
      </td>
      <td className="w-0 p-1 text-right" width={0}>
        <Button
          loading={isLoading}
          onClick={() =>
            mutate({
              sku: value.sku,
              binLocation,
              locationHandle: location,
              barcode,
            })
          }
        >
          Save
        </Button>
      </td>
    </tr>
  );
}
