import { ReactNode } from 'react';
import Mount from './Mount';

type Props = {
  readonly children: ReactNode;
  readonly onClose: () => void;
  readonly heading?: ReactNode;
};

const Modal = ({ heading, children, onClose }: Props) => {
  return (
    <Mount>
      <div
        className="bg-black bg-opacity-75 fixed inset-0 overflow-hidden flex items-center p-5"
        onClick={(event) => {
          if (event.target === event.currentTarget) {
            onClose();
          }
        }}
      >
        <section className="relative flex flex-col bg-white shadow-2xl w-full max-w-xl mx-auto max-h-full overflow-hidden rounded">
          {heading && <div className="bg-ui-200 p-5">{heading}</div>}
          <div className="p-5 overflow-auto">{children}</div>
        </section>
      </div>
    </Mount>
  );
};

export default Modal;
