import { AxiosInstance } from 'axios';

type Variables = {
  readonly id: number;
};

export function deletePackingPreset(client: AxiosInstance) {
  return async (variables: Variables) => {
    await client.delete(`packing-presets/${variables.id}`);
  };
}
