import { AxiosInstance } from 'axios';
import { OrderReturn } from '../types';

type Variables = {
  readonly orderId: number;
};

export function returnOrder(client: AxiosInstance) {
  return async (variables: Variables) => {
    const { data } = await client.post<OrderReturn>(
      `orders/${variables.orderId}/return`,
    );

    return data;
  };
}
