import { useAuth0 } from '@auth0/auth0-react';
import { faLockOpen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import { Redirect, Route, Switch } from 'react-router';
import { useClient } from '../api';
import { useSettings } from '../hooks/useSettings';
import AboutView from '../views/AboutView';
import DataExportView from '../views/DataExportView';
import OrdersView from '../views/OrdersView';
import ReportView from '../views/ReportView';
import SettingsView from '../views/SettingsView';
import SummaryView from '../views/SummaryView';
import Navigation from './Layout/Navigation';

const sideTheme = (expanded: boolean) =>
  classNames('flex-shrink-0', {
    'w-52': expanded,
    'w-16': !expanded,
  });

const App = () => {
  const { isAuthenticated, isLoading, loginWithRedirect, user } = useAuth0();
  const [{ navExpanded }, setSettings] = useSettings();
  const { getApplicationMetadata, getMe } = useClient();

  const { data: metadata } = useQuery(['metadata'], getApplicationMetadata, {
    staleTime: Infinity,
  });

  const { data: me } = useQuery(['me'], getMe);

  if (isLoading) {
    return (
      <div className="fixed inset-0 bg-ui-100 flex items-center">
        <div className="text-center w-full text-ui-500">
          <FontAwesomeIcon icon={faLockOpen} /> Authenticating&hellip;
        </div>
      </div>
    );
  }

  if (!isAuthenticated) {
    loginWithRedirect();

    return null;
  }

  return (
    <div className="flex overflow-hidden">
      <div className={sideTheme(navExpanded)}>
        <Navigation
          permissions={me?.permissions ?? []}
          expanded={navExpanded}
          onToggleExpanded={() => setSettings({ navExpanded: !navExpanded })}
        />
      </div>

      <main className="flex-grow overflow-auto">
        <Switch>
          {metadata && (
            <>
              <Route path="/" exact render={() => <Redirect to="/orders" />} />
              <Route
                path="/orders/:id?"
                render={() => (
                  <OrdersView
                    metadata={metadata}
                    permissions={me?.permissions ?? []}
                  />
                )}
              />
              <Route path="/summary" render={() => <SummaryView />} />
              <Route
                path="/report"
                render={() => <ReportView sources={metadata.sources} />}
              />
              <Route
                path="/data-export"
                render={() => (
                  <DataExportView
                    sources={metadata.sources}
                    statuses={metadata.statuses}
                  />
                )}
              />
              <Route path="/settings" render={() => <SettingsView />} />
              <Route path="/about" render={() => <AboutView />} />
            </>
          )}
        </Switch>
      </main>
    </div>
  );
};

export default App;
