import { AxiosInstance } from 'axios';

export function getDocumentUrl(
  client: AxiosInstance,
  tokenProvider: () => Promise<string>,
) {
  return async (
    ids: readonly number[],
    format: 'html' | 'pdf',
  ): Promise<string> => {
    return client.getUri({
      url: client.defaults.baseURL!.replace(/\/+$/, '') + '/documents',
      params: {
        token: await tokenProvider(),
        ids: ids.join(','),
        format,
      },
    });
  };
}
