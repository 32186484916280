import { ReactNode } from 'react';

type Props = {
  readonly children?: ReactNode;
  readonly label?: string;
  readonly className?: string;
};

const Field = ({ label, children, className }: Props) => {
  return (
    <div className={className}>
      {label && (
        <label className="block text-xs text-ui-600 mb-1">{label}</label>
      )}
      <div>{children}</div>
    </div>
  );
};

export default Field;
