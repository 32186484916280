import { AxiosInstance } from 'axios';
import { QueryFunctionContext } from 'react-query';
import type { User } from '../types';

export function getMe(client: AxiosInstance) {
  return async ({
    queryKey: [_],
  }: QueryFunctionContext<[string]>): Promise<User> => {
    const { data } = await client.get<User>(`auth`);

    return data;
  };
}
