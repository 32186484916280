import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { useClient } from '../../api';
import { OrderSource, YearMonth } from '../../api/types';
import MonthSelector from '../../components/Input/MonthSelector';
import Select from '../../components/Input/Select';
import SimplePage from '../../components/Layout/SimplePage';
import View from '../../components/Layout/View';
import Report from './components/Report';

type Props = {
  readonly sources: readonly OrderSource[];
};

export const ReportView = ({ sources }: Props) => {
  const { getOrderMonths } = useClient();
  const { data: months } = useQuery(['months'], getOrderMonths);

  const [location, setLocation] = useState<string>();
  const [selected, setSelected] = useState<YearMonth>();

  useEffect(() => {
    months && setSelected(months[0]);
  }, [months]);

  return (
    <>
      <Helmet>
        <title>Reports &middot; OMD</title>
      </Helmet>
      <View
        heading={
          <h2 className="text-lg font-bold tracking-wider self-center">
            Report
          </h2>
        }
        controls={
          <>
            <Select
              value={location ?? ''}
              onChange={setLocation}
              options={[
                { label: 'All Locations', value: '' },
                { label: 'Frenchs Forest', value: 'frenchs-forest' },
                { label: 'Huntingwood', value: 'huntingwood' },
              ]}
            />
            {months && selected && (
              <MonthSelector
                value={selected}
                months={months}
                onChange={(value) => setSelected(value)}
              />
            )}
          </>
        }
      >
        <SimplePage>
          {selected && (
            <Report
              sources={sources}
              month={selected.month}
              location={location}
              year={selected.year}
            />
          )}
        </SimplePage>
      </View>
    </>
  );
};
