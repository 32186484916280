import { AxiosInstance } from 'axios';
import { CreateShipmentModel, ShipmentQuote } from '../types';

type Variables = {
  readonly orderId: number;
  readonly models: readonly CreateShipmentModel[];
};

export function createShipmentQuotes(client: AxiosInstance) {
  return async (variables: Variables) => {
    const responses = await Promise.all(
      variables.models.map((model) =>
        client.post<ShipmentQuote>(
          `orders/${variables.orderId}/shipment-quote`,
          model,
        ),
      ),
    );

    return responses.map((response) => response.data);
  };
}
