import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { useClient } from '../../api';
import SimplePage from '../../components/Layout/SimplePage';
import View from '../../components/Layout/View';
import { useEnvironment } from '../../hooks/useEnvironment';
import Release from './components/Release';

export const AboutView = () => {
  const { name, version } = useEnvironment();
  const { getReleases } = useClient();
  const { data: releases } = useQuery(['releases'], getReleases);

  return (
    <>
      <Helmet>
        <title>About &middot; OMD</title>
      </Helmet>
      <View
        heading={
          <h2 className="text-lg font-bold tracking-wider self-center">
            About
          </h2>
        }
      >
        <SimplePage>
          <p>
            The &ldquo;OMD&rdquo; (Order Management Dashboard) is designed,
            developed &amp; maintained by{' '}
            <a
              className="text-blue-800 hover:text-blue-600"
              href="https://weareneat.com.au"
              target="_blank"
              rel="noreferrer noopener"
            >
              Neat Australia
            </a>
            .
          </p>
          <p>
            For technical support, please email{' '}
            <a
              className="text-blue-800 hover:text-blue-600"
              href="mailto:support@weareneat.com.au?subject=OMD"
              target="_blank"
              rel="noreferrer noopener"
            >
              support@weareneat.com.au
            </a>
            .
          </p>
          <div className="pt-5">
            <p>
              Environment:{' '}
              <span className="font-mono">{name ?? 'unknown'}</span>
            </p>
            <p>
              Version: <span className="font-mono">{version ?? 'unknown'}</span>
            </p>
          </div>

          <div className="pt-5 mt-5 border-t border-ui-100">
            <h3 className="mb-5 text-lg font-bold">Releases</h3>

            {releases?.map((release) => (
              <Release
                key={release.name}
                name={release.name}
                created={release.created}
                description={release.description}
                commitSha={release.commitSha}
              />
            ))}
          </div>
        </SimplePage>
      </View>
    </>
  );
};
