import { AxiosInstance } from 'axios';
import { QueryFunctionContext } from 'react-query';
import { OrderCount } from '../types';

export function getCounts(client: AxiosInstance) {
  return async ({
    queryKey: [_, statuses],
  }: QueryFunctionContext<[string, readonly string[]]>): Promise<
    readonly OrderCount[]
  > => {
    const { data } = await client.get<readonly OrderCount[]>(
      `stats/orders/counts`,
      {
        params: { statuses },
      },
    );

    return data;
  };
}
