import { faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

type Props = {
  readonly onChange: (value: string) => void;
};

const Search = ({ onChange }: Props) => {
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [query, setQuery] = useState('');

  const emitChange = (value: string) => {
    onChange(value);

    if (timer) {
      clearTimeout(timer);
      setTimer(undefined);
    }
  };

  useEffect(() => {
    timer && clearTimeout(timer);
    setTimer(setTimeout(() => emitChange(query), 500));
  }, [query]);

  useEffect(() => {
    return () => {
      timer && clearTimeout(timer);
    };
  });

  return (
    <div className="bg-ui-100 relative rounded-full shadow-inner">
      <input
        type="text"
        className="bg-transparent text-ui-800 focus:outline-none px-5 w-full py-2 pr-12 placeholder-ui-400"
        placeholder="Search by customer name, email address, phone number..."
        value={query}
        onChange={(event) => setQuery(event.target.value)}
      />

      <span className="flex absolute top-0 bottom-0 pr-5 text-ui-800 right-0">
        {query.length === 0 ? (
          <FontAwesomeIcon className="self-center" icon={faSearch} />
        ) : (
          <button
            onClick={(event) => {
              event.preventDefault();
              setQuery('');
            }}
          >
            <FontAwesomeIcon className="self-center" icon={faTimes} />
          </button>
        )}
      </span>
    </div>
  );
};

export default Search;
