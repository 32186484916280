import { MouseEvent } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { useClient } from '../../../../api';
import { ApplicationMetadata, User } from '../../../../api/types';
import Mount from '../../../../components/Layout/Mount';
import { SourceMarker } from '../../../../components/Misc/SourceMarker/SourceMarker';
import ActionBar from './components/ActionBar';
import Navigation from './components/Navigation';
import Page from './components/Page';
import Summary from './components/Summary';

type Props = {
  readonly id: number;
  readonly metadata: ApplicationMetadata;
  readonly permissions: User['permissions'];
  readonly onClose: () => void;
};

export const OrderPanel = ({ id, metadata, permissions, onClose }: Props) => {
  const { getOrder } = useClient();
  const { data, isFetching, refetch } = useQuery(['order', id], getOrder);

  const handleClickOut = (event: MouseEvent) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <Mount>
      {data && (
        <Helmet>
          <title>Order {data.sourceReference} &middot; OMD</title>
        </Helmet>
      )}
      <div
        className="bg-black fixed inset-0 bg-opacity-50"
        onClick={handleClickOut}
      >
        <section className="bg-white flex shadow-lg absolute inset-0 w-full max-w-7xl left-auto overflow-hidden">
          {isFetching && !data && <p className="p-10">Loading...</p>}
          {data && (
            <>
              <div className="bg-ui-300 flex flex-col w-64 overflow-hidden flex-shrink-0">
                <div className="h-16 flex flex-shrink-0 items-center bg-ui-700">
                  <div className="px-5">
                    <SourceMarker source={data.source.handle as any} />{' '}
                    <span className="inline-block align-middle text-white pl-2 text-sm">
                      #{data.sourceReference}
                    </span>
                  </div>
                </div>

                <div className="flex-grow overflow-auto">
                  <div className="border-b border-ui-400 p-5">
                    <Summary permissions={permissions} order={data} />
                  </div>

                  <Navigation
                    orderId={id}
                    noteCount={data.notes.length}
                    hasShipment={data.shipments.length > 0}
                    hasReturn={
                      data.returns.length > 0 &&
                      data.returns.every((line) => line.completed)
                    }
                    canReturn={data.source.supportsReturns}
                  />
                </div>
              </div>
              <div className="flex-grow flex flex-col">
                <ActionBar
                  order={data}
                  onUpdate={() => refetch()}
                  onClose={() => onClose()}
                />

                <div className="overflow-auto flex-grow">
                  <Page
                    permissions={permissions}
                    metadata={metadata}
                    order={data}
                    onUpdate={() => refetch()}
                  />
                </div>
              </div>
            </>
          )}
        </section>
      </div>
    </Mount>
  );
};
