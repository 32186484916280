import { AxiosInstance } from 'axios';

type Variables = {
  readonly sku: string;
  readonly locationHandle: string;
  readonly binLocation: string;
  readonly barcode: string;
};

export function upsertSku(client: AxiosInstance) {
  return async (variables: Variables) => {
    await client.post(`skus`, variables);
  };
}
