import { AxiosInstance } from 'axios';
import { QueryFunctionContext } from 'react-query';
import type { Order } from '../types';

export function getOrder(client: AxiosInstance) {
  return async ({
    queryKey: [_, id],
  }: QueryFunctionContext<[string, number]>): Promise<Order> => {
    const { data } = await client.get<Order>(`orders/${id}`);

    return data;
  };
}
