import { format } from 'date-fns';
import { OrderStatusChange } from '../../../../../../../api/types';
import Modal from '../../../../../../../components/Layout/Modal';
import InlineUser from '../../../../../../../components/Misc/InlineUser';
import StatusIndicator from '../../../../../../../components/Misc/StatusIndicator';

type Props = {
  readonly change: OrderStatusChange;
  readonly onClose: () => void;
};

const StatusChangeModal = ({ change, onClose }: Props) => {
  return (
    <Modal heading={<>Status Change</>} onClose={onClose}>
      <div className="grid gap-x-10 gap-y-5 grid-cols-2">
        <div>
          <h6 className="text-sm text-ui-500 mb-1">Status</h6>
          <StatusIndicator status={change.status.handle as any} />
        </div>

        <div>
          <h6 className="text-sm text-ui-500 mb-1">Date</h6>
          {format(new Date(change.created), 'do MMM yyyy')} at{' '}
          {format(new Date(change.created), 'hh:mma')}
        </div>

        <div>
          <h6 className="text-sm text-ui-500 mb-1">Updated By</h6>
          {change.user ? (
            <InlineUser user={change.user} />
          ) : change.meta?.origin === 'axapta' ? (
            <>Axapta</>
          ) : (
            '~'
          )}
        </div>

        <div>
          <h6 className="text-sm text-ui-500 mb-1">Reason</h6>
          {change.reason ? change.reason.description : '~'}
        </div>

        <div className="col-span-2">
          <h6 className="text-sm text-ui-500 mb-1">Message</h6>
          {change.meta?.message ?? '~'}
        </div>

        <div className="col-span-2">
          <h6 className="text-sm text-ui-500 mb-1">Metadata</h6>
          {change.meta ? (
            <pre className="bg-ui-100 p-5 overflow-auto text-xs text-ui-900 rounded">
              <code>{JSON.stringify(change.meta, null, 2)}</code>
            </pre>
          ) : (
            '~'
          )}
        </div>
      </div>
    </Modal>
  );
};

export default StatusChangeModal;
