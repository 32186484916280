import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Option = {
  readonly label?: string;
  readonly value: string;
  readonly disabled?: boolean;
};

type Props = {
  readonly value: string;
  readonly placeholder?: string;
  readonly options: readonly Option[];
  readonly onChange: (value: string) => void;
};

const Select = ({ value, options, placeholder, onChange }: Props) => {
  return (
    <div className="relative block w-full bg-white border border-ui-400 shadow-sm rounded px-3 py-2 focus:outline-none focus:border-ui-500">
      <select
        value={value}
        onChange={(event) => onChange(event.target.value)}
        className="w-full appearance-none cursor-pointer focus:outline-none bg-transparent"
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label ?? option.value}
          </option>
        ))}
      </select>

      <span className="absolute flex top-0 right-2 w-4 bottom-0 pointer-events-none text-ui-500">
        <FontAwesomeIcon className="self-center text-xs" icon={faChevronDown} />
      </span>
    </div>
  );
};

export default Select;
