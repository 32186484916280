import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';

type Props = {
  readonly heading: string;
  readonly links: readonly {
    readonly label: string;
    readonly link: string;
    readonly icon: IconProp;
  }[];
};

const Group = ({ heading, links }: Props) => {
  return (
    <div>
      <h4 className="tracking-wide text-sm text-ui-600 font-semibold">
        {heading}
      </h4>
      <div className="pt-4">
        {links.map((link) => (
          <NavLink
            key={link.link}
            to={link.link}
            className="flex items-center group text-ui-400"
            activeClassName="text-ui-700"
          >
            <span className="w-6 text-ui-300">
              <FontAwesomeIcon icon={link.icon} />
            </span>
            <span className="flex-grow group-hover:text-ui-600 text-sm">
              {link.label}
            </span>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default Group;
