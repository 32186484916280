import { AxiosInstance } from 'axios';
import { QueryFunctionContext } from 'react-query';
import {
  ApplicationMetadata,
  OrderShipmentProvider,
  OrderSource,
  OrderStatus,
  OrderStatusChangeReason,
  PackingPreset,
} from '../types';

export function getApplicationMetadata(client: AxiosInstance) {
  return async ({
    queryKey: [string],
  }: QueryFunctionContext<[string]>): Promise<ApplicationMetadata> => {
    const [
      { data: sources },
      { data: statuses },
      { data: reasons },
      { data: shipmentProviders },
      { data: packingPresets },
      { data: locations },
    ] = await Promise.all([
      client.get<readonly OrderSource[]>('order-sources'),
      client.get<readonly OrderStatus[]>('order-statuses'),
      client.get<readonly OrderStatusChangeReason[]>('order-statuses/reasons'),
      client.get<readonly OrderShipmentProvider[]>('order-shipment-providers'),
      client.get<readonly PackingPreset[]>('packing-presets'),
      client.get<ApplicationMetadata['locations']>('locations'),
    ]);

    return {
      sources,
      statuses,
      reasons,
      shipmentProviders,
      packingPresets,
      locations,
    };
  };
}
