import { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ServerError } from '../../../../../api/error';
import { isExpressShipping } from '../../../../../api/support';
import { ApplicationMetadata, Order, User } from '../../../../../api/types';
import { canPrintOrder } from '../../../../../support';
import DetailsView from '../views/DetailsView';
import NotesView from '../views/NotesView';
import ReturnView from '../views/ReturnView';
import ShipmentView from '../views/ShipmentView';
import StatusView from '../views/StatusView';
import ErrorMessage from './ErrorMessage';

type Props = {
  readonly order: Order;
  readonly metadata: ApplicationMetadata;
  readonly permissions: User['permissions'];
  readonly onUpdate: () => Promise<any>;
};

const Page = ({ order, metadata, permissions, onUpdate }: Props) => {
  const location = useLocation();

  const [error, setError] = useState<ServerError | null>(null);

  // Discard errors as we move around tabs.
  useEffect(() => setError(null), [location]);

  return (
    <>
      {error && <ErrorMessage error={error} />}
      <Switch>
        <Route
          path="/orders/:id/status"
          render={() => (
            <StatusView
              orderId={order.id}
              onUpdate={() => onUpdate()}
              onError={(error) => setError(error)}
              statuses={metadata.statuses}
              reasons={metadata.reasons}
              changes={order.statusChanges}
              canPrint={canPrintOrder(order.axaptaOrderId, order.source.handle)}
            />
          )}
        />
        <Route
          path="/orders/:id/notes"
          render={() => (
            <NotesView
              orderId={order.id}
              onUpdate={() => onUpdate()}
              notes={order.notes}
            />
          )}
        />
        <Route
          path="/orders/:id/shipment"
          render={() => (
            <ShipmentView
              orderId={order.id}
              onUpdate={() => onUpdate()}
              onError={(error) => setError(error)}
              shipments={order.shipments}
              providers={metadata.shipmentProviders}
              packingPresets={metadata.packingPresets}
              customerReference={order.sourceReference}
              shippingFirstName={order.shippingAddress?.firstName ?? ''}
              shippingLastName={order.shippingAddress?.lastName ?? ''}
              businessName={order.shippingAddress?.businessName ?? ''}
              customerEmail={order.customer?.email ?? ''}
              customerPhone={order.customer?.phone ?? ''}
              addressLine1={order.shippingAddress?.addressLine1 ?? ''}
              addressLine2={order.shippingAddress?.addressLine2 ?? ''}
              suburb={order.shippingAddress?.suburb ?? ''}
              state={order.shippingAddress?.state ?? ''}
              postcode={order.shippingAddress?.postcode ?? ''}
              shippingNotes={order.shippingInfo?.notes ?? ''}
              isExpressShipping={isExpressShipping(
                order.shippingInfo?.type ?? '',
              )}
              permissions={permissions}
              locations={order.locationRefs.map((ref) => ref.location.handle)}
            />
          )}
        />
        <Route
          path="/orders/:id/return"
          render={() => (
            <ReturnView
              orderId={order.id}
              returns={order.returns}
              onUpdate={() => onUpdate()}
            />
          )}
        />
        <Route
          path="/orders/:id"
          render={() => (
            <DetailsView
              orderId={order.id}
              supportsGiftCard={order.source.supportsGiftCard}
              billingAddressId={order.billingAddress?.id ?? undefined}
              billingFirstName={order.billingAddress?.firstName ?? undefined}
              billingLastName={order.billingAddress?.lastName ?? undefined}
              billingAddress1={order.billingAddress?.addressLine1 ?? undefined}
              billingAddress2={order.billingAddress?.addressLine2 ?? undefined}
              billingSuburb={order.billingAddress?.suburb ?? undefined}
              billingState={order.billingAddress?.state ?? undefined}
              billingPostcode={order.billingAddress?.postcode ?? undefined}
              billingCountry={order.billingAddress?.country ?? undefined}
              shippingAddressId={order.shippingAddress?.id ?? undefined}
              shippingFirstName={order.shippingAddress?.firstName ?? undefined}
              shippingLastName={order.shippingAddress?.lastName ?? undefined}
              shippingAddress1={
                order.shippingAddress?.addressLine1 ?? undefined
              }
              shippingAddress2={
                order.shippingAddress?.addressLine2 ?? undefined
              }
              shippingSuburb={order.shippingAddress?.suburb ?? undefined}
              shippingState={order.shippingAddress?.state ?? undefined}
              shippingPostcode={order.shippingAddress?.postcode ?? undefined}
              shippingCountry={order.shippingAddress?.country ?? undefined}
              shippingMethod={order.shippingInfo?.type ?? undefined}
              email={order.customer.email}
              phone={order.customer.phone ?? undefined}
              lines={order.lineItems}
              total={order.total ?? undefined}
              subtotal={order.subtotal ?? undefined}
              gst={order.taxInfo?.cost}
              shippingCost={order.shippingInfo?.cost ?? undefined}
              paymentMethod={order.metadata?.payment_type}
              customerLoyaltyTier={order.metadata?.customer_loyalty_tier}
              customerNotes={order.shippingInfo?.notes ?? undefined}
              giftMessage={order.giftMessage ?? undefined}
              discount={order.discountInfo}
              onUpdate={() => onUpdate()}
            />
          )}
        />
      </Switch>
    </>
  );
};

export default Page;
