import { PublicUserInfo } from '../../api/types';

type Props = {
  readonly user: PublicUserInfo;
};

const InlineUser = ({ user }: Props) => {
  return (
    <div className="inline-block align-middle overflow-ellipsis">
      <span className="flex items-center">
        <img
          className="inline-block w-5 h-5 rounded-full"
          src={user.picture}
          alt=""
        />{' '}
        <span className="ml-2 text-sm text-ui-600 truncate">{user.name}</span>
      </span>
    </div>
  );
};

export default InlineUser;
