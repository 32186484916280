type Props = {
  readonly placeholder?: string;
  readonly value: string;
  readonly onChange: (value: string) => void;
  readonly rows?: number;
};

const Textarea = ({ placeholder, value, rows = 5, onChange }: Props) => {
  return (
    <textarea
      className="block w-full bg-white border border-ui-400 shadow-sm rounded px-3 py-2 focus:outline-none focus:border-ui-500"
      rows={rows}
      placeholder={placeholder}
      value={value}
      onChange={(event) => onChange(event.target.value)}
    />
  );
};

export default Textarea;
