import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ButtonHTMLAttributes, ReactNode } from 'react';

type Props = {
  readonly children?: ReactNode;
  readonly type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  readonly href?: string;
  readonly icon?: IconProp;
  readonly disabled?: boolean;
  readonly loading?: boolean;
  readonly counter?: number;
  readonly onClick?: () => void;
};

const theme = (disabled: boolean, loading: boolean, hasChildren: boolean) => {
  const disabledState = disabled || loading;

  return classNames(
    'inline-block align-middle relative h-8 rounded whitespace-nowrap',
    {
      'bg-ui-800 text-white hover:bg-ui-900 shadow-sm': !disabledState,
      'bg-transparent text-ui-400 cursor-not-allowed bg-ui-200': disabledState,
      'w-8': !hasChildren,
      'px-4': hasChildren,
    },
  );
};

const iconTheme = (hasChildren: boolean) =>
  classNames('text-sm mx-auto', {
    'mr-3 opacity-80': hasChildren,
  });

const Button = ({
  children,
  type,
  href,
  icon,
  disabled,
  loading,
  counter,
  onClick,
}: Props) => {
  const content = (
    <span className="flex h-full items-center">
      <span className="w-full text-center">
        {icon && (
          <FontAwesomeIcon className={iconTheme(!!children)} icon={icon} />
        )}
        {children}
      </span>
    </span>
  );

  if (href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        className={theme(!!disabled, !!loading, !!children)}
      >
        {content}
      </a>
    );
  }

  return (
    <button
      type={type}
      disabled={disabled || loading}
      className={theme(!!disabled, !!loading, !!children)}
      onClick={(event) => {
        if (onClick) {
          event.preventDefault();
          onClick();
        }
      }}
    >
      {content}

      {!!counter && (
        <span className="absolute top-0 right-0 -mt-2 -mr-2 w-5 h-5 rounded-full overflow-hidden bg-red-600 text-white font-normal leading-5 text-xs text-center shadow">
          {counter}
        </span>
      )}
    </button>
  );
};

export default Button;
