import classNames from 'classnames';
import { ReactNode } from 'react';

type Props = {
  readonly children: ReactNode;
  readonly heading: ReactNode;
  readonly controls?: ReactNode;
  readonly sidebar?: ReactNode;
  readonly scrollable?: boolean;
};

const contentTheme = (scrollable: boolean) =>
  classNames('flex-grow', {
    'overflow-auto': scrollable,
  });

const View = ({
  heading,
  controls,
  sidebar,
  children,
  scrollable = true,
}: Props) => {
  return (
    <section className="h-screen bg-ui-100 flex flex-col w-full overflow-hidden">
      <div className="flex-shrink-0 flex items-center h-16 px-5 bg-ui-200">
        <div className="flex items-center w-full">
          <div className="flex-grow">{heading}</div>
          {controls && (
            <div className="flex items-center w-96 flex-shrink-0 justify-end space-x-3">
              {controls}
            </div>
          )}
        </div>
      </div>
      <div className="flex-grow flex overflow-hidden">
        {sidebar && (
          <div className="w-52 flex-shrink-0 overflow-auto">{sidebar}</div>
        )}
        <div className={contentTheme(scrollable)}>{children}</div>
      </div>
    </section>
  );
};

export default View;
