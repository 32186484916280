import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { QueryClient } from 'react-query';
import { SERVER_URL } from '../config';
import { ServerError } from './error';
import { addNote } from './mutations/addNote';
import { assignOrderLocations } from './mutations/assignOrderLocations';
import { completeReturn } from './mutations/completeReturn';
import { createDocuments } from './mutations/createDocuments';
import { createPackingPreset } from './mutations/createPackingPreset';
import { createShipment } from './mutations/createShipment';
import { createShipmentQuotes } from './mutations/createShipmentQuotes';
import { deletePackingPreset } from './mutations/deletePackingPreset';
import { pushOrder } from './mutations/pushOrder';
import { returnOrder } from './mutations/returnOrder';
import { setPackingPresetDefault } from './mutations/setPackingPresetDefault';
import { updateAddress } from './mutations/updateAddress';
import { updatePackingPresetOrder } from './mutations/updatePackingPresetOrder';
import { updateStatus } from './mutations/updateStatus';
import { upsertSku } from './mutations/upsertSku';
import { getApplicationMetadata } from './queries/getApplicationMetadata';
import { getCounts } from './queries/getCounts';
import { getMe } from './queries/getMe';
import { getOrder } from './queries/getOrder';
import { getOrderMonths } from './queries/getOrderMonths';
import { getOrders } from './queries/getOrders';
import { getPackingPresets } from './queries/getPackingPresets';
import { getReleases } from './queries/getReleases';
import { getReport } from './queries/getReport';
import { getSummary } from './queries/getSummary';
import { searchSkus } from './queries/searchSkus';
import { getDocumentUrl } from './support/getDocumentUrl';
import { getExportUrl } from './support/getExportUrl';

export function useClient() {
  const { getAccessTokenSilently } = useAuth0();

  const client = axios.create({
    baseURL: SERVER_URL,
  });

  client.interceptors.request.use(async (config) => {
    return {
      ...config,
      headers: {
        ...config.headers,
        authorization: `bearer ${await getAccessTokenSilently()}`,
      },
    };
  });

  client.interceptors.response.use(
    (response: AxiosResponse<unknown>) => response,
    (error: AxiosError<any>) => {
      return error.response
        ? Promise.reject(
            new ServerError(
              error.response?.data?.message ?? error.message,
              error.response?.data ?? null,
            ),
          )
        : Promise.reject(new Error(error.message));
    },
  );

  return {
    getMe: getMe(client),
    getApplicationMetadata: getApplicationMetadata(client),
    getCounts: getCounts(client),
    getOrder: getOrder(client),
    getOrderMonths: getOrderMonths(client),
    getOrders: getOrders(client),
    getReport: getReport(client),
    getSummary: getSummary(client),
    getPackingPresets: getPackingPresets(client),
    addNote: addNote(client),
    searchSkus: searchSkus(client),
    createDocuments: createDocuments(client),
    createShipment: createShipment(client),
    createShipmentQuotes: createShipmentQuotes(client),
    createPackingPreset: createPackingPreset(client),
    updatePackingPresetOrder: updatePackingPresetOrder(client),
    updateStatus: updateStatus(client),
    deletePackingPreset: deletePackingPreset(client),
    setPackingPresetDefault: setPackingPresetDefault(client),
    getDocumentUrl: getDocumentUrl(client, getAccessTokenSilently),
    getExportUrl: getExportUrl(client, getAccessTokenSilently),
    returnOrder: returnOrder(client),
    completeReturn: completeReturn(client),
    getReleases: getReleases(client),
    assignOrderLocations: assignOrderLocations(client),
    updateAddress: updateAddress(client),
    upsertSku: upsertSku(client),
    pushOrder: pushOrder(client),
  };
}

export default new QueryClient({
  //
});
