import classNames from 'classnames';
import { useQuery } from 'react-query';
import { useClient } from '../../../api';
import { MonthlySourceReport, OrderSource } from '../../../api/types';
import { formatMoney } from '../../../formatting/formatMoney';
import { formatPercentage } from '../../../formatting/formatPercentage';
import Row from './Row';

type Props = {
  readonly sources: readonly OrderSource[];
  readonly month: number;
  readonly year: number;
  readonly location?: string;
};

const sentimentTheme = (good: boolean) =>
  classNames({
    'text-green-500': good,
    'text-red-500': !good,
  });

const Report = ({ sources, month, year, location }: Props) => {
  const { getReport } = useClient();
  const { data } = useQuery(['report', month, year, location], getReport);

  const sum = (field: Exclude<keyof MonthlySourceReport, 'source'>): number => {
    if (data) {
      return data.sources.reduce(
        (total: number, report: MonthlySourceReport) =>
          total + (report[field] ?? 0),
        0,
      );
    }

    return 0;
  };

  const average = (
    field: Exclude<keyof MonthlySourceReport, 'source'>,
  ): number => {
    if (data) {
      const nonZeroReports: readonly MonthlySourceReport[] =
        data.sources.filter((report: MonthlySourceReport) => !!report[field]);

      return sum(field) / nonZeroReports.length;
    }

    return 0;
  };

  if (data) {
    return (
      <table className="w-full">
        <thead>
          <tr>
            <th />
            {data.sources.map((entry) => {
              const source = sources.find(
                (source) => source.handle === entry.source,
              )!;

              return (
                <th
                  key={source.id}
                  className="text-left pb-2 font-semibold text-ui-600"
                >
                  {source.name}
                </th>
              );
            })}
            <th className="text-left pb-2 font-semibold text-ui-600">
              Total / Avg
            </th>
          </tr>
        </thead>
        <tbody>
          <Row
            heading="Sales"
            report={data}
            target="sales"
            format="money"
            total={sum('sales')}
            threshold="budget"
            totalThreshold={sum('budget')}
          />
          <Row
            heading="Budget"
            report={data}
            target="budget"
            format="money"
            total={sum('budget')}
          />

          <tr className="border-t border-ui-200 border-b">
            <th className="text-left py-2">% Sales to Budget</th>
            {data.sources.map((source) => (
              <td className="py-2" key={source.source}>
                <span className={sentimentTheme(source.sales > source.budget)}>
                  {formatPercentage(source.sales / source.budget)}
                </span>
              </td>
            ))}
            <td className="py-2">
              <span className={sentimentTheme(sum('sales') > sum('budget'))}>
                {formatPercentage(sum('sales') / sum('budget'))}
              </span>
            </td>
          </tr>
          {/* <tr className="text-left border-t border-ui-200 border-b">
            <th className="py-2">Planned Staff Cost</th>
            <td className="py-2" colSpan={data.sources.length} />
            <td className="py-2">{formatMoney(data.plannedLabourCost)}</td>
          </tr>
          <tr className="text-left border-t border-ui-200 border-b">
            <th>Actual Staff Cost</th>
            <td className="py-2" colSpan={data.sources.length} />
            <td className="py-2">{formatMoney(data.actualLabourCost)}</td>
          </tr> */}
          <tr>
            <th className="text-left py-2">Average Order Sale</th>
            {data.sources.map((source) => (
              <td className="py-2" key={source.source}>
                {formatMoney(source.sales / source.shipped)}
              </td>
            ))}
            <td className="py-2">
              {formatMoney(sum('sales') / sum('shipped'))}
            </td>
          </tr>

          <Row
            heading="Shipped"
            report={data}
            target="shipped"
            total={sum('shipped')}
          />
          <Row
            heading="Refunded"
            report={data}
            target="returned"
            total={sum('returned')}
          />
          <Row
            heading="Cancelled"
            report={data}
            target="cancelled"
            total={sum('cancelled')}
          />
          <Row
            heading="Pending"
            subheading="(In History)"
            report={data}
            target="pending"
            total={sum('pending')}
          />
          <Row
            heading="Time to Shipped"
            report={data}
            target="averageTimeToShippedWithPending"
            format="duration"
            total={average('averageTimeToShippedWithPending')}
          />
          <Row
            heading="Time to Shipped"
            subheading="(Never Pending)"
            report={data}
            target="averageTimeToShippedNoPending"
            format="duration"
            total={average('averageTimeToShippedNoPending')}
          />
          <Row
            heading="Print to Shipped"
            report={data}
            target="averageTimeToShippedFromPrintedWithPending"
            format="duration"
            total={average('averageTimeToShippedFromPrintedWithPending')}
          />
          <Row
            heading="Print to Shipped"
            subheading="(Never Pending)"
            report={data}
            target="averageTimeToShippedFromPrintedNoPending"
            format="duration"
            total={average('averageTimeToShippedFromPrintedNoPending')}
          />
          <Row
            heading="Total Items Shipped"
            report={data}
            target="totalIndividualShippedItems"
            total={sum('totalIndividualShippedItems')}
          />
          <Row
            heading="Total Order Lines"
            report={data}
            target="totalOrderLines"
            total={sum('totalOrderLines')}
          />
          <Row
            heading="Total Shipping Cost"
            report={data}
            target="totalShippingCost"
            format="money"
            total={sum('totalShippingCost')}
          />
          <Row
            heading="Average Shipping Cost"
            report={data}
            target="averageShippingCost"
            format="money"
            total={average('averageShippingCost')}
          />
        </tbody>
      </table>
    );
  }

  return <div>Loading...</div>;
};

export default Report;
