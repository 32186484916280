import { ReactNode } from 'react';

type Props = {
  readonly children: ReactNode;
};

const ControlPanel = ({ children }: Props) => {
  return (
    <aside className="w-64 bg-ui-100 border-r border-ui-200 overflow-auto flex-shrink-0">
      {children}
    </aside>
  );
};

export default ControlPanel;
