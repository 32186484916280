import { format } from 'date-fns';
import { useMutation } from 'react-query';
import { useClient } from '../../../../../../../api';
import { OrderReturnType } from '../../../../../../../api/types';
import Button from '../../../../../../../components/Input/Button';

type Props = {
  readonly id: number;
  readonly created: Date;
  readonly completed: Date | null;
  readonly type: OrderReturnType | null;
  readonly onComplete: () => Promise<void>;
};

const ReturnLineItem = ({
  id,
  created,
  completed,
  type,
  onComplete,
}: Props) => {
  const { completeReturn } = useClient();

  const { mutate, isLoading } = useMutation(completeReturn, {
    onSuccess: () => onComplete(),
  });

  return (
    <div className="grid grid-cols-4">
      <div>
        <div className="text-sm text-ui-500">Returned</div>
        {format(created, 'do MMM yyyy hh:mma')}
      </div>
      <div>
        <div className="text-sm text-ui-500">Actioned</div>
        {completed ? format(completed, 'do MMM yyyy hh:mma') : '-'}
      </div>
      <div className="col-span-2 text-right">
        {type ? (
          <>
            <div className="text-sm text-ui-500">Status</div>
            {type.name}
          </>
        ) : (
          <>
            <span className="mr-2">
              <Button
                loading={isLoading}
                onClick={() => mutate({ returnId: id, type: 'exchanged' })}
              >
                Exchanged
              </Button>
            </span>
            <span>
              <Button
                loading={isLoading}
                onClick={() => mutate({ returnId: id, type: 'refunded' })}
              >
                Refunded
              </Button>
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default ReturnLineItem;
