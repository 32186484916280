import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';

type Props = {
  readonly children: ReactNode;
  readonly label: string;
  readonly icon: IconProp;
};

const Item = ({ label, icon, children }: Props) => {
  return (
    <div className="mb-4 last:mb-0">
      <div className="text-sm text-ui-500 mb-1">
        <FontAwesomeIcon className="inline-block align-middle" icon={icon} />{' '}
        <span className="inline-block align-middle ml-1">{label}</span>
      </div>
      <div className="text-ui-800">{children}</div>
    </div>
  );
};

export default Item;
