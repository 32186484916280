import { ReactNode } from 'react';

type Props = {
  readonly children: ReactNode;
  readonly footer?: ReactNode;
};

const SplitPanel = ({ children, footer }: Props) => {
  return (
    <section className="flex flex-col h-full overflow-hidden">
      <div className="flex-grow overflow-auto p-5">{children}</div>
      {footer && <div className="flex-shrink-0 p-5 bg-ui-100">{footer}</div>}
    </section>
  );
};

export default SplitPanel;
