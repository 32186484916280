import { faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { OrderNote } from '../../../../../../api/types';
import DataTable from '../../../../../../components/Layout/DataTable';
import InlineUser from '../../../../../../components/Misc/InlineUser';
import Heading from '../../components/Heading';
import SplitPanel from '../../components/SplitPanel';
import Form from './components/Form';

type Props = {
  readonly orderId: number;
  readonly notes: readonly OrderNote[];
  readonly onUpdate: () => Promise<any>;
};

export const NotesView = ({ orderId, notes, onUpdate }: Props) => {
  return (
    <SplitPanel footer={<Form orderId={orderId} onUpdate={onUpdate} />}>
      <Heading text="Notes" />

      <div>
        {notes.length > 0 ? (
          <DataTable
            rightAlignLast={false}
            headings={['Date', <FontAwesomeIcon icon={faUser} />, 'Body']}
            rows={notes.map((note) => ({
              key: note.id,
              values: [
                format(new Date(note.created), 'do MMM yyyy hh:mma'),
                note.user ? <InlineUser user={note.user} /> : '~',
                <div className="whitespace-normal">{note.body}</div>,
              ],
            }))}
          />
        ) : (
          <p>No notes.</p>
        )}
      </div>
    </SplitPanel>
  );
};
