import {
  faChevronLeft,
  faChevronRight,
  faChevronsLeft,
  faChevronsRight,
} from '@fortawesome/pro-light-svg-icons';
import { useEffect, useState } from 'react';
import Button from '../Input/Button';
import Select from '../Input/Select';

type Props = {
  readonly offset: number;
  readonly limit: number;
  readonly total: number;
  readonly onChange: (offset: number) => void;
};

const Pagination = ({ offset, limit, total, onChange }: Props) => {
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(1);

  const handleChange = (value: number) => {
    const highest = Math.ceil((pages - 1) * limit);

    onChange(Math.max(0, Math.min(highest, value)));
  };

  useEffect(() => {
    const totalPages = Math.ceil(total / limit);
    const highest = Math.ceil((totalPages - 1) * limit);

    if (offset > highest) {
      onChange(0);
    }

    setPage(Math.ceil(offset / limit));
    setPages(totalPages);
  }, [offset, limit, total]);

  if (total === 0) {
    return <div>Page 1 of 1</div>;
  }

  return (
    <div>
      <span className="mr-1">
        <Button
          disabled={page <= 0}
          onClick={() => handleChange(0)}
          icon={faChevronsLeft}
        />
      </span>
      <Button
        disabled={page <= 0}
        onClick={() => handleChange(offset - limit)}
        icon={faChevronLeft}
      />

      <span className="inline-block mx-2">
        Page
        <span className="w-24 inline-block mx-2">
          <Select
            value={page.toString()}
            options={Array.from(Array(pages)).map((_, index) => ({
              label: (index + 1).toString(),
              value: index.toString(),
            }))}
            onChange={(value) => onChange(limit * parseInt(value))}
          />
        </span>
        of {pages}
      </span>

      <Button
        disabled={page >= pages - 1}
        onClick={() => handleChange(offset + limit)}
        icon={faChevronRight}
      />
      <span className="ml-1">
        <Button
          disabled={page >= pages - 1}
          onClick={() => handleChange(Math.ceil((pages - 1) * limit))}
          icon={faChevronsRight}
        />
      </span>
    </div>
  );
};

export default Pagination;
