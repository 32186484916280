import {
  faCaretDown,
  faCaretUp,
  faStar,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { PackingPreset } from '../../../../../api/types';
import Button from '../../../../../components/Input/Button';

type Props = {
  readonly preset: PackingPreset;
  readonly onMoveUp: () => void;
  readonly onMoveDown: () => void;
  readonly onDelete: () => void;
  readonly onToggleDefault: () => void;
};

const defaultButtonTheme = (isDefault: boolean) =>
  classNames({
    'text-ui-200 hover:text-ui-300': !isDefault,
    'text-ui-800': isDefault,
  });

const PresetRow = ({
  preset,
  onMoveUp,
  onMoveDown,
  onDelete,
  onToggleDefault,
}: Props) => {
  return (
    <tr>
      <td>
        <button
          onClick={() => onToggleDefault()}
          className={defaultButtonTheme(preset.isDefault)}
        >
          <FontAwesomeIcon icon={faStar} />
        </button>
      </td>
      <td>{preset.name}</td>
      <td>{preset.length}cm</td>
      <td>{preset.width}cm</td>
      <td>{preset.height}cm</td>
      <td>{preset.weight ? `${preset.weight / 1000}kg` : '-'}</td>
      <td>
        <span className="mr-1">
          <Button onClick={() => onMoveUp()} icon={faCaretUp} />
        </span>
        <span>
          <Button onClick={() => onMoveDown()} icon={faCaretDown} />
        </span>
      </td>
      <td>
        <Button onClick={() => onDelete()} icon={faTimes} />
      </td>
    </tr>
  );
};

export default PresetRow;
