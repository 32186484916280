import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

type Props = {
  readonly showText?: boolean;
  readonly priority: number;
  readonly onChange: (priority: number) => void;
};

const theme = ({ priority, showText }: Partial<Props>) =>
  classNames('flex h-8 leading-8 text-center rounded', {
    'bg-green-600 text-green-300 hover:text-green-200': priority === -1,
    'bg-yellow-400 text-yellow-200 hover:text-yellow-100': priority === 0,
    'bg-red-600 text-red-300 hover:text-red-200': priority === 1,
    'w-8': !showText,
    'pr-2': showText,
  });

const PriorityIndicator = ({ priority, onChange, showText }: Props) => {
  return (
    <button
      className={theme({ priority, showText })}
      onClick={(event) => {
        event.preventDefault();
        onChange(priority === 1 ? -1 : priority + 1);
      }}
    >
      <span className="self-center text-center w-8">
        <FontAwesomeIcon icon={faExclamationTriangle} />
      </span>
      {showText && (
        <span className="self-center font-semibold text-sm">
          {priority === -1 && <>Low Priority</>}
          {priority === 0 && <>Medium Priority</>}
          {priority === 1 && <>High Priority</>}
        </span>
      )}
    </button>
  );
};

export default PriorityIndicator;
