import {
  faChartLine,
  faChevronLeft,
  faChevronRight,
  faCircleQuestion,
  faCog,
  faFileCsv,
  faList,
  faTv,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { User } from '../../../api/types';
import Logo from './components/Logo';
import NavigationItem from './components/NavigationItem';
import Profile from './components/Profile';

type Props = {
  readonly permissions: User['permissions'];
  readonly expanded: boolean;
  readonly onToggleExpanded: () => void;
};

const theme = (expanded: boolean) =>
  classNames(
    'flex h-screen flex-col top-0 left-0 bottom-0 bg-ui-900 text-white shadow-xl',
    {
      'w-52': expanded,
      'w-16': !expanded,
    },
  );

const expanderTheme = (expanded: boolean) =>
  classNames(
    'px-5 py-3 text-ui-600 block w-full text-sm border-t border-ui-800 tracking-wider hover:text-ui-400',
    {
      'text-left': expanded,
      'text-center': !expanded,
    },
  );

export const Navigation = ({
  permissions,
  expanded,
  onToggleExpanded,
}: Props) => {
  return (
    <header className={theme(expanded)}>
      <div className="flex-shrink-0 h-16 bg-black bg-opacity-10">
        <Logo expanded={expanded} />
      </div>

      <nav className="flex-grow">
        <NavigationItem
          expanded={expanded}
          text="Orders"
          to="/orders"
          icon={faList}
        />
        {permissions.includes('read:summary') && (
          <NavigationItem
            expanded={expanded}
            text="Summary"
            to="/summary"
            icon={faTv}
          />
        )}
        {permissions.includes('read:reports') && (
          <>
            <NavigationItem
              expanded={expanded}
              text="Report"
              to="/report"
              icon={faChartLine}
            />
            <NavigationItem
              expanded={expanded}
              text="Data Export"
              to="/data-export"
              icon={faFileCsv}
            />
          </>
        )}
        {permissions.includes('modify:settings') && (
          <NavigationItem
            expanded={expanded}
            text="Settings"
            to="/settings"
            icon={faCog}
          />
        )}
        <NavigationItem
          expanded={expanded}
          text="About"
          to="/about"
          icon={faCircleQuestion}
        />
      </nav>

      <div>
        <Profile permissions={permissions} expanded={expanded} />
      </div>

      <div>
        <button
          className={expanderTheme(expanded)}
          onClick={(event) => {
            event.preventDefault();
            onToggleExpanded();
          }}
        >
          {expanded ? (
            <>
              <FontAwesomeIcon className="mr-2" icon={faChevronLeft} /> Collapse
            </>
          ) : (
            <FontAwesomeIcon icon={faChevronRight} />
          )}
        </button>
      </div>
    </header>
  );
};
