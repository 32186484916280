import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useState } from 'react';

type Props = {
  readonly children: ReactNode;
  readonly heading: string;
};

const Group = ({ children, heading }: Props) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <div className="p-5 border-b border-ui-200">
      <div className="flex text-ui-600 text-sm">
        <h4 className="flex-grow self-center tracking-wide font-semibold">
          {heading}
        </h4>
        <button
          className="flex-shrink-0 self-center hover:text-ui-900"
          onClick={(event) => {
            event.preventDefault();
            setExpanded(!expanded);
          }}
        >
          <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronUp} />
        </button>
      </div>

      {expanded && <div className="pt-4">{children}</div>}
    </div>
  );
};

export default Group;
