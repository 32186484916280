export type Environment = {
  readonly name: 'dev' | 'staging' | 'production';
  readonly version: string;
};

export function useEnvironment(): Partial<Environment> {
  return {
    name: process.env.REACT_APP_ENVIRONMENT as Environment['name'],
    version: process.env.REACT_APP_VERSION,
  };
}
