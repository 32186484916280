import { decode } from 'html-entities';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useClient } from '../../../../../../../api';
import Button from '../../../../../../../components/Input/Button';
import Field from '../../../../../../../components/Input/Field';
import Textarea from '../../../../../../../components/Input/Textarea';

type Props = {
  readonly orderId: number;
  readonly message: string;
};

const GiftCardForm = ({ orderId, message }: Props) => {
  const [text, setText] = useState(decode(message));

  const { createDocuments, getDocumentUrl } = useClient();
  const { mutateAsync, isLoading } = useMutation(createDocuments);

  const handleSubmit = async () => {
    const documents = await mutateAsync([
      { id: orderId, type: 'giftCard', data: { overrideMessage: text } },
    ]);

    const url = await getDocumentUrl(
      documents.map((document) => document.id),
      'pdf',
    );

    window.open(url);
  };

  const canSubmit = text.length > 0;

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      <div className="grid gap-5">
        <Field>
          <Textarea value={text} onChange={(value) => setText(value)} />
        </Field>
        <div>
          <Button disabled={!canSubmit} loading={isLoading} type="submit">
            Print Gift Card
          </Button>
        </div>
      </div>
    </form>
  );
};

export default GiftCardForm;
