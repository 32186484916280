import { faFileDownload } from '@fortawesome/pro-light-svg-icons';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { useClient } from '../api';
import { OrderSourceHandle, OrderStatusHandle } from '../api/enums';
import { OrderSource, OrderStatus, YearMonth } from '../api/types';
import Button from '../components/Input/Button';
import MonthSelector from '../components/Input/MonthSelector';
import Multiselect from '../components/Input/Multiselect';
import SimplePage from '../components/Layout/SimplePage';
import View from '../components/Layout/View';

type Props = {
  readonly sources: readonly OrderSource[];
  readonly statuses: readonly OrderStatus[];
};

const DataExportView = ({ sources, statuses }: Props) => {
  const { getOrderMonths, getExportUrl } = useClient();
  const { data: months } = useQuery(['months'], getOrderMonths);

  const [selected, setSelected] = useState<YearMonth>();
  const [selectedSources, setSelectedSources] = useState<readonly string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<readonly string[]>(
    [],
  );
  const [selectedOptions, setSelectedOptions] = useState<readonly string[]>([]);

  useEffect(() => {
    months && setSelected(months[0]);
  }, [months]);

  const canDownload = selectedSources.length > 0 && selectedStatuses.length > 0;

  const handleDownload = async () => {
    const url = await getExportUrl({
      options: selectedOptions,
      sources: selectedSources as OrderSourceHandle[],
      statuses: selectedStatuses as OrderStatusHandle[],
      month: selected?.month,
      year: selected?.year,
    });

    window.open(url, '_blank');
  };

  return (
    <>
      <Helmet>
        <title>Data Exports &middot; OMD</title>
      </Helmet>
      <View
        heading={
          <div className="grid grid-cols-3 gap-5">
            <div className="col-span-2 flex">
              <h2 className="text-lg font-bold tracking-wider self-center">
                Data Export
              </h2>
            </div>
            <div>
              {months && selected && (
                <MonthSelector
                  value={selected}
                  months={months}
                  onChange={(value) => setSelected(value)}
                />
              )}
            </div>
          </div>
        }
      >
        <SimplePage>
          <div className="grid grid-cols-3 gap-5 border-b border-ui-200 pb-10 mb-10">
            <div>
              <h3 className="mb-5 text-lg font-semibold">Sources</h3>

              <Multiselect
                value={selectedSources}
                options={sources.map((source) => ({
                  label: source.name,
                  value: source.handle,
                }))}
                onChange={(value) => setSelectedSources(value)}
              />
            </div>
            <div>
              <h3 className="mb-5 text-lg font-semibold">Statuses</h3>

              <Multiselect
                value={selectedStatuses}
                options={statuses.map((status) => ({
                  label: status.name,
                  value: status.handle,
                }))}
                onChange={(value) => setSelectedStatuses(value)}
              />
            </div>

            <div>
              <h3 className="mb-5 text-lg font-semibold">Options</h3>

              <Multiselect
                value={selectedOptions}
                options={[
                  {
                    label: 'Include Line Items',
                    value: 'include-line-items',
                  },
                ]}
                onChange={(value) => setSelectedOptions(value)}
              />
            </div>
          </div>

          <div>
            <Button
              disabled={!canDownload}
              onClick={() => handleDownload()}
              icon={faFileDownload}
            >
              Download
            </Button>
          </div>
        </SimplePage>
      </View>
    </>
  );
};

export default DataExportView;
