import {
  faArrowTurnDownRight,
  faFileLines,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from 'react-query';
import { useClient } from '../../../../../api';
import { OrderStatusHandle } from '../../../../../api/enums';
import { shouldUpdateToPrinted } from '../../../../../api/support';
import { Order } from '../../../../../api/types';
import Button from '../../../../../components/Input/Button';
import PriorityIndicator from '../../../../../components/Misc/PriorityIndicator';
import StatusIndicator from '../../../../../components/Misc/StatusIndicator';
import { canPrintOrder } from '../../../../../support';
import { useMemo } from 'react';

type Props = {
  readonly order: Order;
  readonly onUpdate: () => Promise<any>;
  readonly onClose: () => void;
};

const ActionBar = ({ order, onUpdate, onClose }: Props) => {
  const canMoveToHW = useMemo(() => {
    const locations = order.locationRefs.map((ref) => ref.location.handle);
    const [latestStatusChange] = order.statusChanges;

    if (
      order.isCombo &&
      latestStatusChange.status.handle === OrderStatusHandle.Printed &&
      locations.length === 1 &&
      locations[0] === 'frenchs-forest'
    ) {
      return true;
    }

    return false;
  }, [order]);

  const {
    createDocuments,
    updateStatus,
    getDocumentUrl,
    assignOrderLocations,
  } = useClient();

  const {
    mutate: createDocumentsMutation,
    isLoading: createDocumentsMutationLoading,
  } = useMutation(createDocuments, {
    onSuccess: async (documents) => {
      if (shouldUpdateToPrinted(order)) {
        try {
          await updateStatus({
            id: order.id,
            status: OrderStatusHandle.Printed,
          });
        } catch (error: any) {
          console.error(`Could not update order to printed: ${error.message}`);
        }
      }

      const url = await getDocumentUrl(
        documents.map((document) => document.id),
        'pdf',
      );

      window.open(url, '_blank');

      return onUpdate();
    },
  });

  const {
    mutate: assignOrderLocationsMutation,
    isLoading: assignOrderLocationsMutationLoading,
  } = useMutation(assignOrderLocations, {
    onSuccess: async () => {
      try {
        await updateStatus({
          id: order.id,
          status: OrderStatusHandle.Ready,
          message: 'Locations changed',
        });
      } catch (error: any) {
        console.error(`Could not update order to ready: ${error.message}`);
      }

      return onUpdate();
    },
  });

  const handlePrint = () => {
    createDocumentsMutation([
      {
        id: order.id,
        type: 'pickingSlip',
      },
      {
        id: order.id,
        type: 'packingSlip',
      },
    ]);
  };

  const hasDocuments =
    order.documents.filter((document) =>
      ['packingSlip', 'pickingSlip'].includes(document.type),
    ).length > 0;

  return (
    <div className="h-16 flex items-center px-5 bg-ui-600 flex-shrink-0">
      <div className="flex-grow flex items-center">
        <div className="flex-grow">
          <span className="inline-block align-middle mr-3">
            <PriorityIndicator priority={order.priority} onChange={() => {}} />
          </span>
          <span className="inline-block align-middle">
            <StatusIndicator
              status={order.statusChanges[0].status.handle as any}
              reason={order.statusChanges[0].reason?.description}
            />
          </span>
        </div>
        <div className="text-right">
          {canMoveToHW && (
            <span className="mr-3">
              <Button
                loading={assignOrderLocationsMutationLoading}
                onClick={() =>
                  assignOrderLocationsMutation({
                    id: order.id,
                    model: {
                      locations: ['huntingwood'],
                    },
                  })
                }
                icon={faArrowTurnDownRight}
              >
                Assign to Huntingwood
              </Button>
            </span>
          )}

          <Button
            disabled={
              !hasDocuments &&
              !canPrintOrder(order.axaptaOrderId, order.source.handle)
            }
            loading={createDocumentsMutationLoading}
            onClick={handlePrint}
            icon={faFileLines}
          >
            {hasDocuments ? 'Download Documents' : 'Print Documents'}
          </Button>

          <button
            className="text-ui-200 font-lg ml-5 hover:text-white"
            onClick={() => onClose()}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActionBar;
