import { format } from 'date-fns';
import { YearMonth } from '../../api/types';
import Select from './Select';

type Props = {
  readonly value: YearMonth;
  readonly months: readonly YearMonth[];
  readonly onChange: (value: YearMonth) => void;
};

const MonthSelector = ({ value, months, onChange }: Props) => {
  const key = (value: YearMonth) =>
    value.year.toString() + '-' + value.month.toString();

  return (
    <Select
      value={key(value)}
      onChange={(value) =>
        onChange(months.find((month) => value === key(month))!)
      }
      options={months.map(({ year, month }, index) => {
        const date = new Date(year, month - 1, 1, 0, 0, 0);

        return {
          label: format(date, 'MMMM yyyy'),
          value: key({ year, month }),
        };
      })}
    />
  );
};

export default MonthSelector;
