import { faCreditCard } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useClient } from '../../../../../../../../api';
import {
  ShippingAuthority,
  ShippingDestinationType,
} from '../../../../../../../../api/enums';
import { ServerError } from '../../../../../../../../api/error';
import {
  CreateShipmentLineItemModel,
  CreateShipmentModel,
  OrderShipmentProvider,
  PackingPreset,
} from '../../../../../../../../api/types';
import Button from '../../../../../../../../components/Input/Button';
import Field from '../../../../../../../../components/Input/Field';
import Select from '../../../../../../../../components/Input/Select';
import TextInput from '../../../../../../../../components/Input/TextInput';
import Textarea from '../../../../../../../../components/Input/Textarea';
import { formatMoney } from '../../../../../../../../formatting/formatMoney';
import Section from '../../../../components/Section';
import Items from './components/Items';

type Props = {
  readonly orderId: number;
  readonly onShipmentCreated: () => Promise<any>;
  readonly onError: (error: ServerError | null) => void;
  readonly defaultCustomerReference?: string;
  readonly defaultShippingFirstName?: string;
  readonly defaultShippingLastName?: string;
  readonly defaultBusinessName?: string;
  readonly defaultCustomerEmail?: string;
  readonly defaultCustomerPhone?: string;
  readonly defaultAddressLine1?: string;
  readonly defaultAddressLine2?: string;
  readonly defaultSuburb?: string;
  readonly defaultState?: string;
  readonly defaultPostcode?: string;
  readonly defaultShippingNotes?: string;
  readonly isExpressShipping: boolean;
  readonly providers: readonly OrderShipmentProvider[];
  readonly packingPresets: readonly PackingPreset[];
};

export const Form = ({
  orderId,
  onShipmentCreated: onUpdate,
  onError,
  defaultCustomerReference,
  defaultShippingFirstName,
  defaultShippingLastName,
  defaultBusinessName,
  defaultCustomerEmail,
  defaultCustomerPhone,
  defaultAddressLine1,
  defaultAddressLine2,
  defaultSuburb,
  defaultState,
  defaultPostcode,
  defaultShippingNotes,
  isExpressShipping,
  providers,
  packingPresets,
}: Props) => {
  const { createShipmentQuotes, createShipment } = useClient();

  const {
    data: shipmentQuotes,
    mutate: getShipmentQuotesMutation,
    isLoading: getShipmentQuotesMutationLoading,
  } = useMutation(createShipmentQuotes, {
    onMutate: () => onError(null),
    onError: (error) => onError(error as ServerError),
  });

  const {
    mutate: createShipmentMutation,
    isLoading: createShipmentMutationLoading,
  } = useMutation(createShipment, {
    onMutate: () => onError(null),
    onError: (error) => onError(error as ServerError),
    onSuccess: () => onUpdate(),
  });

  const [customerName, setCustomName] = useState(
    [defaultShippingFirstName, defaultShippingLastName].join(' ').trim(),
  );
  const [businessName, setBusinessName] = useState(defaultBusinessName ?? '');
  const [customerEmail, setCustomerEmail] = useState(
    defaultCustomerEmail ?? '',
  );
  const [customerPhone, setCustomerPhone] = useState(
    defaultCustomerPhone ?? '',
  );
  const [addressType, setAddressType] = useState(
    ShippingDestinationType.StandardAddress,
  );
  const [addressLine1, setAddressLine1] = useState(defaultAddressLine1 ?? '');
  const [addressLine2, setAddressLine2] = useState(defaultAddressLine2 ?? '');
  const [suburb, setSuburb] = useState(defaultSuburb ?? '');
  const [state, setState] = useState(defaultState ?? '');
  const [postcode, setPostcode] = useState(defaultPostcode ?? '');

  const defaultPackingPreset = packingPresets.find(
    (preset) => preset.isDefault,
  );

  const [items, setItems] = useState<readonly CreateShipmentLineItemModel[]>([
    defaultPackingPreset
      ? {
          weight: defaultPackingPreset.weight
            ? (defaultPackingPreset.weight / 1000).toFixed(2)
            : '',
          length: defaultPackingPreset.length.toString(),
          width: defaultPackingPreset.width.toString(),
          height: defaultPackingPreset.height.toString(),
        }
      : {
          weight: '',
          length: '',
          width: '',
          height: '',
        },
  ]);

  const [method, setMethod] = useState('');
  const [customerReference1, setCustomerReference1] = useState(
    defaultCustomerReference ?? '',
  );
  const [customerReference2, setCustomerReference2] = useState('');
  const [authority, setAuthority] = useState(
    ShippingAuthority.SignatureRequired,
  );
  const [packagingType, setPackagingType] = useState('');

  const [deliveryInstructions, setDeliveryInstructions] = useState(
    defaultShippingNotes ?? '',
  );

  const products = useMemo(() => {
    let rows = [];

    for (const provider of providers) {
      for (const product of provider.products) {
        const quote = shipmentQuotes
          ? shipmentQuotes.find((quote) => quote.product.id === product.id)
          : null;

        rows.push({
          label: quote
            ? `${provider.name} - ${product.name} (${formatMoney(quote.quote)})`
            : `${provider.name} - ${product.name}`,
          value: product.id.toString(),
        });
      }
    }

    return rows;
  }, [providers, shipmentQuotes]);

  // Provider based on the currently selected product.
  const provider = providers.find((provider) =>
    provider.products.some((product) => product.id.toString() === method),
  );

  useEffect(() => {
    // Default shipping method selection.
    const auspost = providers.find((provider) =>
      provider.handle.includes('auspost'),
    );

    if (auspost) {
      const defaultPackagingType = auspost.products.find(
        (product) =>
          isExpressShipping ===
          product.handle.toLowerCase().indexOf('express') >= 0,
      );

      setMethod(defaultPackagingType?.id.toString() ?? '');
    }
  }, [providers]);

  useEffect(() => {
    // Default packaging type.
    if (provider) {
      setPackagingType(
        provider.defaultPackagingType
          ? provider.defaultPackagingType.id.toString()
          : provider.packagingTypes[0].id.toString(),
      );
    }
  }, [provider]);

  const hasValidLines =
    items.length > 0 &&
    items.every(
      (line) =>
        Number.isFinite(parseFloat(line.width)) &&
        Number.isFinite(parseFloat(line.height)) &&
        Number.isFinite(parseFloat(line.length)) &&
        Number.isFinite(parseFloat(line.weight)),
    );

  const shipmentModel: CreateShipmentModel = {
    shippingFullName: customerName,
    productId: parseInt(method),
    packagingTypeId: parseInt(packagingType),
    shippingDestinationType: addressType,
    customerReference1,
    customerReference2,
    businessName,
    customerEmail,
    customerPhone,
    addressLine1,
    addressLine2,
    suburb,
    state,
    postcode,
    instructions: deliveryInstructions,
    authority,
    lineItems: items,
  };

  const handleGenerateQuotes = () => {
    getShipmentQuotesMutation({
      orderId,
      models: products.map((product) => ({
        ...shipmentModel,
        productId: parseInt(product.value),
      })),
    });
  };

  const handleSubmit = () => {
    createShipmentMutation({ id: orderId, model: shipmentModel });
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      <Section heading="Recipient Details">
        <div className="grid grid-cols-2 gap-5">
          <Field label="Customer Name">
            <TextInput
              placeholder="Customer Name"
              value={customerName}
              onChange={(value) => setCustomName(value)}
            />
          </Field>
          <Field label="Business Name">
            <TextInput
              placeholder="Business Name"
              value={businessName}
              onChange={(value) => setBusinessName(value)}
            />
          </Field>
          <Field label="Customer Email Address">
            <TextInput
              placeholder="Customer Email Address"
              value={customerEmail}
              onChange={(value) => setCustomerEmail(value)}
            />
          </Field>
          <Field label="Customer Phone">
            <TextInput
              placeholder="Customer Phone"
              value={customerPhone}
              onChange={(value) => setCustomerPhone(value)}
            />
          </Field>
          <div className="col-span-2">
            <Field label="Address Type">
              <Select
                value={addressType}
                onChange={(value) => setAddressType(value as any)}
                placeholder="Address Type"
                options={[
                  {
                    label: 'Standard Address',
                    value: ShippingDestinationType.StandardAddress,
                  },
                  {
                    label: 'Parcel Collect',
                    value: ShippingDestinationType.ParcelCollect,
                  },
                  {
                    label: 'Parcel Locker',
                    value: ShippingDestinationType.ParcelLocker,
                  },
                ]}
              />
            </Field>
          </div>
          <Field label="Address Line 1">
            <TextInput
              placeholder="Address Line 1"
              value={addressLine1}
              onChange={(value) => setAddressLine1(value)}
            />
          </Field>
          <Field label="Address Line 2">
            <TextInput
              placeholder="Address Line 2"
              value={addressLine2}
              onChange={(value) => setAddressLine2(value)}
            />
          </Field>
          <Field label="Suburb">
            <TextInput
              placeholder="Suburb"
              value={suburb}
              onChange={(value) => setSuburb(value)}
            />
          </Field>
          <div className="grid grid-cols-2 gap-5">
            <Field label="State">
              <Select
                placeholder="State"
                value={state}
                onChange={(value) => setState(value)}
                options={[
                  { value: 'ACT', label: 'Australian Capital Territory' },
                  { value: 'NSW', label: 'New South Wales' },
                  { value: 'NT', label: 'Northern Territory' },
                  { value: 'QLD', label: 'Queensland' },
                  { value: 'SA', label: 'South Australia' },
                  { value: 'TAS', label: 'Tasmania' },
                  { value: 'VIC', label: 'Victoria' },
                  { value: 'WA', label: 'Western Australia' },
                ]}
              />
            </Field>
            <Field label="Postcode">
              <TextInput
                placeholder="Postcode"
                value={postcode}
                onChange={(value) => setPostcode(value)}
              />
            </Field>
          </div>
        </div>
      </Section>

      <Section heading={`Items (${items.length})`}>
        <Items
          value={items}
          onChange={(items) => setItems(items)}
          packingPresets={packingPresets}
        />
      </Section>

      <Section heading="Shipping Details">
        <div className="grid grid-cols-4 gap-5 mb-5">
          <div className="col-span-2">
            <Field label="Shipping Method">
              <Select
                value={method}
                onChange={(value) => setMethod(value)}
                placeholder="Method"
                options={products}
              />
            </Field>
          </div>

          <Field label="Customer Reference 1">
            <TextInput
              placeholder="Customer Reference 1"
              value={customerReference1}
              onChange={(value) => setCustomerReference1(value)}
            />
          </Field>
          <Field label="Customer Reference 2">
            <TextInput
              placeholder="Customer Reference 2"
              value={customerReference2}
              onChange={(value) => setCustomerReference2(value)}
            />
          </Field>
        </div>

        <div className="mb-5">
          <Button
            onClick={() => handleGenerateQuotes()}
            disabled={!hasValidLines}
            loading={getShipmentQuotesMutationLoading}
          >
            <FontAwesomeIcon className="mr-2" icon={faCreditCard} />
            Generate Quotes
          </Button>
        </div>

        <div className="grid grid-cols-2 gap-5 mb-5">
          <Field label="Authority">
            <Select
              value={authority}
              onChange={(value) => setAuthority(value as any)}
              options={[
                {
                  label: 'Authority to leave',
                  value: ShippingAuthority.AuthorityToLeave,
                },
                {
                  label: 'Authority to leave - request by recipient',
                  value: ShippingAuthority.AuthorityToLeaveRequestByRecipient,
                },
                {
                  label: 'Signature required (card if not home)',
                  value: ShippingAuthority.SignatureRequired,
                },
              ]}
            />
          </Field>
          {provider && provider.packagingTypes.length > 1 && (
            <Field label="Packaging Type">
              <Select
                value={packagingType}
                onChange={(value) => setPackagingType(value)}
                options={provider.packagingTypes.map((packagingType) => ({
                  label: packagingType.name,
                  value: packagingType.id.toString(),
                }))}
              />
            </Field>
          )}
        </div>

        <Field label="Delivery Instructions">
          <Textarea
            placeholder="Delivery instructions..."
            value={deliveryInstructions}
            onChange={(value) => setDeliveryInstructions(value)}
          />
        </Field>
      </Section>

      <div className="mt-5">
        <Button
          disabled={!hasValidLines}
          loading={createShipmentMutationLoading}
          type="submit"
        >
          Submit Shipment
        </Button>
      </div>
    </form>
  );
};
