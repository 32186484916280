import { AxiosInstance } from 'axios';
import { QueryFunctionContext } from 'react-query';
import type { Order, SearchOrdersCriteria } from '../types';

export type Response = {
  readonly offset: number;
  readonly limit: number;
  readonly total: number;
  readonly orders: readonly Order[];
};

export function getOrders(client: AxiosInstance) {
  return async ({
    queryKey: [_, criteria],
  }: QueryFunctionContext<
    [string, Partial<SearchOrdersCriteria>]
  >): Promise<Response> => {
    const { data } = await client.get<Response>('orders', {
      params: criteria,
    });

    return data;
  };
}
