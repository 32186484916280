import { AxiosInstance } from 'axios';

type Variables = {
  readonly id: number;
  readonly status: string;
  readonly reason?: number;
  readonly message?: string;
  readonly consignment?: string;
};

export function updateStatus(client: AxiosInstance) {
  return async (variables: Variables) => {
    const { data } = await client.patch<void>(`orders/${variables.id}/status`, {
      status: variables.status,
      reason: variables.reason,
      meta:
        variables.message || variables.consignment
          ? {
              message: variables.message ?? undefined,
              consignmentId: variables.consignment ?? undefined,
            }
          : undefined,
      ignoreErrors: false,
    });

    return data;
  };
}
