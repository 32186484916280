import { AxiosInstance } from 'axios';
import { QueryFunctionContext } from 'react-query';

export type SKUResponse = {
  readonly sku: string;
  readonly binLocation: string | null;
  readonly location: {
    readonly id: number;
    readonly name: string;
    readonly handle: string;
  } | null;
  readonly barcode: string | null;
};

export function searchSkus(client: AxiosInstance) {
  return async ({
    queryKey: [_, sku],
  }: QueryFunctionContext<[string, string]>): Promise<
    readonly SKUResponse[]
  > => {
    const { data } = await client.get<readonly SKUResponse[]>(`skus`, {
      params: {
        q: sku,
      },
    });

    return data;
  };
}
