export enum OrderSourceHandle {
  Sandbox = 'sandbox',
  GeorgJensen = 'georgJensen',
  DavidJones = 'davidJones',
  Alessi = 'alessi',
  Iconic = 'iconic',
}

export enum OrderStatusHandle {
  Cancelled = 'cancelled',
  New = 'new',
  Ready = 'ready',
  Pending = 'pending',
  Printed = 'printed',
  Refunded = 'refunded',
  Shipped = 'shipped',
  Invoiced = 'invoiced',
}

export enum ShippingAuthority {
  AuthorityToLeave = 'authorityToLeave',
  AuthorityToLeaveRequestByRecipient = 'authorityToLeaveRequestByRecipient',
  SignatureRequired = 'signatureRequired',
}

export enum ShippingDestinationType {
  StandardAddress = 'STANDARD_ADDRESS',
  ParcelLocker = 'PARCEL_LOCKER',
  ParcelCollect = 'PARCEL_COLLECT',
}
