import classNames from 'classnames';

type Props = {
  readonly heading: string;
  readonly count?: number;
  readonly theme?: 'pending' | 'in-progress' | 'new';
};

const bgTheme = ({ theme }: Partial<Props>) =>
  classNames(
    'rounded px-3 py-2 relative leading-snug bg-white border border-ui-200 w-36 text-right',
    {
      'bg-ui-100': !theme,
      // 'bg-blue-50': theme === 'in-progress',
      // 'bg-yellow-50': theme === 'pending',
      // 'bg-green-50': theme === 'new',
    },
  );

const dotTheme = ({ theme }: Partial<Props>) =>
  classNames('w-3 h-3 rounded-full absolute top-2 left-2', {
    'bg-ui-300': !theme,
    'bg-blue-300': theme === 'in-progress',
    'bg-yellow-300': theme === 'pending',
    'bg-green-300': theme === 'new',
  });

const Count = ({ heading, count, theme }: Props) => {
  return (
    <div className={bgTheme({ theme })}>
      <h6 className="text-xs font-light">{heading}</h6>
      <div className="tracking-wider font-semibold">
        {count ? count.toLocaleString() : '~'}
      </div>

      <span className={dotTheme({ theme })} />
    </div>
  );
};

export default Count;
