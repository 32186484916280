import { faPlus, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import {
  CreateShipmentLineItemModel,
  PackingPreset,
} from '../../../../../../../../../api/types';
import Button from '../../../../../../../../../components/Input/Button';
import Select from '../../../../../../../../../components/Input/Select';
import DataTable from '../../../../../../../../../components/Layout/DataTable';

type Props = {
  readonly value: readonly CreateShipmentLineItemModel[];
  readonly onChange: (value: readonly CreateShipmentLineItemModel[]) => void;
  readonly packingPresets: readonly PackingPreset[];
};

const Items = ({ value, onChange, packingPresets }: Props) => {
  const [packingPresetId, setPackingPresetId] = useState(
    packingPresets.find((preset) => preset.isDefault)?.id.toString() ?? '',
  );

  const handleChangeValue = (
    field: keyof CreateShipmentLineItemModel,
    index: number,
    val: string,
  ) => {
    onChange(
      value.map((item, position) =>
        index === position
          ? {
              ...item,
              [field]: val,
            }
          : item,
      ),
    );
  };

  const handleAdd = () => {
    const preset = packingPresets.find(
      (preset) => preset.id.toString() === packingPresetId,
    );

    onChange(
      value.concat(
        preset
          ? {
              weight: preset.weight ? (preset.weight / 1000).toFixed(2) : '',
              length: preset.length.toString(),
              width: preset.width.toString(),
              height: preset.height.toString(),
            }
          : {
              weight: '',
              length: '',
              width: '',
              height: '',
            },
      ),
    );
  };

  return (
    <section>
      <div className="mb-5">
        <DataTable
          squashFirst={false}
          headings={[
            'Weight (kg)',
            'Length (cm)',
            'Width (cm)',
            'Height (cm)',
            '',
          ]}
          rows={value.map((item, i) => ({
            key: i,
            values: [
              <input
                className="w-full focus:outline-none h-8 leading-8"
                type="text"
                placeholder="Weight"
                value={item.weight}
                onChange={(event) =>
                  handleChangeValue('weight', i, event.target.value)
                }
              />,
              <input
                className="w-full focus:outline-none h-8 leading-8"
                type="text"
                placeholder="Length"
                value={item.length}
                onChange={(event) =>
                  handleChangeValue('length', i, event.target.value)
                }
              />,
              <input
                className="w-full focus:outline-none h-8 leading-8"
                type="text"
                placeholder="Width"
                value={item.width}
                onChange={(event) =>
                  handleChangeValue('width', i, event.target.value)
                }
              />,
              <input
                className="w-full focus:outline-none h-8 leading-8"
                type="text"
                placeholder="Height"
                value={item.height}
                onChange={(event) =>
                  handleChangeValue('height', i, event.target.value)
                }
              />,
              <Button
                icon={faTimes}
                onClick={() =>
                  onChange(value.filter((_, position) => i !== position))
                }
              />,
            ],
          }))}
        />
      </div>

      <div className="flex justify-end">
        <div>
          <Select
            value={packingPresetId ?? ''}
            onChange={(value) => setPackingPresetId(value)}
            placeholder="Custom Dimensions"
            options={packingPresets.map((preset) => ({
              label: preset.name,
              value: preset.id.toString(),
            }))}
          />
        </div>
        <div className="pl-5 self-center">
          <Button onClick={() => handleAdd()}>
            <FontAwesomeIcon className="mr-2" icon={faPlus} />
            Add Line Item
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Items;
