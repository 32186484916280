import { faFileDownload } from '@fortawesome/pro-light-svg-icons';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useClient } from '../../../api';
import { OrderSourceHandle, OrderStatusHandle } from '../../../api/enums';
import { shouldUpdateToPrinted } from '../../../api/support';
import { CreateOrderDocumentModel, Order } from '../../../api/types';
import Button from '../../../components/Input/Button';

type Props = {
  readonly orders: readonly Order[];
  readonly selectedOrderIds: readonly number[];
  readonly selectableOrderIds: readonly number[];
  readonly onUpdateStatuses: () => Promise<any>;
};

const PrintControls = ({
  orders,
  selectedOrderIds,
  selectableOrderIds,
  onUpdateStatuses,
}: Props) => {
  const { createDocuments, getDocumentUrl, updateStatus } = useClient();
  const { mutate, isLoading } = useMutation(createDocuments, {
    onSuccess: async (documents, request) => {
      // Open documents in new tab.
      if (documents) {
        const url = await getDocumentUrl(
          documents.map((document) => document.id),
          'pdf',
        );
        window.open(url, '_blank');
      }

      // IDs for orders where printing or packing slips were printed. De-duped
      // since multiple documents can be included for each order.
      const orderIdsWithDocuments = [
        ...new Set(
          request
            .filter(
              (document) =>
                document.type === 'packingSlip' ||
                document.type === 'pickingSlip',
            )
            .map((document) => document.id),
        ),
      ];

      const orderIdsRequiringUpdate = orders
        .filter(
          (order) =>
            orderIdsWithDocuments.includes(order.id) &&
            shouldUpdateToPrinted(order),
        )
        .map((order) => order.id);

      try {
        await Promise.all(
          orderIdsRequiringUpdate.map((id) =>
            updateStatus({ id, status: OrderStatusHandle.Printed }),
          ),
        );
      } catch (error: any) {
        console.error(
          `Could not update all orders to printed: ${error.message}`,
        );
      }

      if (orderIdsRequiringUpdate.length > 0) {
        return onUpdateStatuses();
      }
    },
  });

  const [selectedDocumentIds, setSelectedDocumentIds] = useState<
    readonly number[]
  >([]);
  const [selectedGJGiftCardIds, setSelectedGJGiftCardIds] = useState<
    readonly number[]
  >([]);
  const [selectedAlessiGiftCardIds, setSelectedAlessiGiftCardIds] = useState<
    readonly number[]
  >([]);

  const printDocuments = () => {
    mutate(
      selectedDocumentIds.reduce<readonly CreateOrderDocumentModel[]>(
        (documents, id) =>
          documents.concat([
            { id, type: 'pickingSlip' },
            { id, type: 'packingSlip' },
          ]),
        [],
      ),
    );
  };

  const printGJGiftCards = () => {
    mutate(
      selectedGJGiftCardIds.map((id) => ({
        id,
        type: 'giftCard',
      })),
    );
  };

  const printAlessiGiftCards = () => {
    mutate(
      selectedAlessiGiftCardIds.map((id) => ({
        id,
        type: 'giftCard',
      })),
    );
  };

  useEffect(() => {
    if (orders) {
      const ordersWithGiftCards = orders.filter(
        (order) => order.source.supportsGiftCard && order.giftMessage,
      );

      // Narrow document printing selection to user-selected ones, or all if
      // there are no user selections made.
      setSelectedDocumentIds(
        selectedOrderIds.length === 0 ? selectableOrderIds : selectedOrderIds,
      );

      const cohort = ordersWithGiftCards.filter((order) => {
        if (selectedOrderIds.length === 0) {
          return selectableOrderIds.includes(order.id);
        }

        return selectedOrderIds.includes(order.id);
      });

      setSelectedGJGiftCardIds(
        cohort
          .filter(
            (order) => order.source.handle === OrderSourceHandle.GeorgJensen,
          )
          .map((order) => order.id),
      );
      setSelectedAlessiGiftCardIds(
        cohort
          .filter((order) => order.source.handle === OrderSourceHandle.Alessi)
          .map((order) => order.id),
      );

      // setSelectedGJGiftCardIds(
      //   selectedOrderIds.length === 0
      //     ? ordersWithGiftCards
      //         .map((order) => order.id)
      //         .filter((id) => selectableOrderIds.includes(id))
      //     : ordersWithGiftCards
      //         .filter((order) => selectedOrderIds.includes(order.id))
      //         .map((order) => order.id),
      // );
    }
  }, [selectedOrderIds, orders]);

  return (
    <span className="inline-block space-x-2">
      <Button
        loading={isLoading}
        disabled={selectedDocumentIds.length === 0}
        onClick={printDocuments}
        icon={faFileDownload}
      >
        <strong className="font-medium">{selectedDocumentIds.length}</strong>{' '}
        Document
        {selectedDocumentIds.length === 1 ? '' : 's'}
      </Button>
      <Button
        loading={isLoading}
        disabled={selectedGJGiftCardIds.length === 0}
        onClick={printGJGiftCards}
        icon={faFileDownload}
      >
        <strong className="font-medium">{selectedGJGiftCardIds.length}</strong>{' '}
        GJ Gift Card
        {selectedGJGiftCardIds.length === 1 ? '' : 's'}
      </Button>
      <Button
        loading={isLoading}
        disabled={selectedAlessiGiftCardIds.length === 0}
        onClick={printAlessiGiftCards}
        icon={faFileDownload}
      >
        <strong className="font-medium">
          {selectedAlessiGiftCardIds.length}
        </strong>{' '}
        Alessi Gift Card
        {selectedAlessiGiftCardIds.length === 1 ? '' : 's'}
      </Button>
    </span>
  );
};

export default PrintControls;
