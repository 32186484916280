import { format } from 'date-fns';
import { useMemo } from 'react';

type Props = {
  readonly name: string;
  readonly created: string;
  readonly description: string;
  readonly commitSha: string;
};

const Release = ({ name, created, description, commitSha }: Props) => {
  const formattedDate = useMemo(() => {
    return format(new Date(created), 'dd MMM yy HH:mm');
  }, [created]);

  return (
    <div className="mb-2 last:mb-0 bg-ui-100 p-5 rounded-md">
      <div className="mb-3">
        <span className="text-white bg-primary rounded-md px-3 py-1 inline-block font-bold text-sm">
          {name}
        </span>{' '}
        <span className="inline-block ml-2">
          {formattedDate} <span className="text-ui-500">({commitSha})</span>
        </span>
      </div>
      <div
        className="prose max-w-none"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );
};

export default Release;
