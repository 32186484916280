import { AxiosInstance } from 'axios';
import { QueryFunctionContext } from 'react-query';
import { PackingPreset } from '../types';

export function getPackingPresets(client: AxiosInstance) {
  return async ({
    queryKey: [_],
  }: QueryFunctionContext<[string]>): Promise<readonly PackingPreset[]> => {
    const { data } = await client.get<readonly PackingPreset[]>(
      `packing-presets`,
    );

    return data;
  };
}
