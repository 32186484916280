import Checkbox from './Checkbox';

type Props = {
  readonly value: readonly string[];
  readonly options: readonly {
    readonly label: string;
    readonly value: string;
  }[];
  readonly onChange: (value: readonly string[]) => void;
};

const Multiselect = ({ value, options, onChange }: Props) => {
  const handleSelect = (option: string, selected: boolean) => {
    onChange(
      selected
        ? value.concat(option)
        : value.filter((entry) => entry !== option),
    );
  };

  return (
    <div>
      {options.map((option) => (
        <div key={option.value} className="mb-2 last:mb-0">
          <Checkbox
            value={value.includes(option.value)}
            onChange={(value) => handleSelect(option.value, value)}
          />
          <span className="inline-block pl-2">{option.label}</span>
        </div>
      ))}
    </div>
  );
};

export default Multiselect;
