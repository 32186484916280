import { Auth0Provider } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import client from './api';
import App from './components/App';
import { AUTH_AUDIENCE, AUTH_CLIENT_ID, AUTH_DOMAIN } from './config';
import './index.css';
import reportWebVitals from './reportWebVitals';

datadogRum.init({
  applicationId: '91dbe7fc-3d0b-4e16-9f10-12c628012eb1',
  clientToken: 'pub0bdbe7f36cfdd497bcadd027ec631631',
  site: 'datadoghq.com',
  service: 'omd',
  env: process.env.REACT_APP_ENVIRONMENT,
  version: process.env.REACT_APP_VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={AUTH_DOMAIN}
      clientId={AUTH_CLIENT_ID}
      useRefreshTokensFallback
      authorizationParams={{
        audience: AUTH_AUDIENCE,
        redirect_uri: window.location.origin,
        scope: 'openid profile email offline_access',
      }}
      cacheLocation="localstorage"
      useRefreshTokens
    >
      <QueryClientProvider client={client}>
        <Router>
          <App />
        </Router>
      </QueryClientProvider>
    </Auth0Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

datadogRum.startSessionReplayRecording();
