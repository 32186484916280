import { ReactNode } from 'react';
import Container from './Container';

type Props = {
  readonly children: ReactNode;
};

const SimplePage = ({ children }: Props) => {
  return (
    <div className="p-5">
      <Container>
        <div className="p-5">{children}</div>
      </Container>
    </div>
  );
};

export default SimplePage;
