import { ReactNode } from 'react';

type Props = {
  readonly children: ReactNode;
  readonly text: string;
};

const Tooltip = ({ children, text }: Props) => {
  return (
    <span className="inline-block relative group cursor-help">
      {children}
      <span className="absolute hidden group-hover:inline-block top-0 -mt-10 left-1/2 transform -translate-x-1/2 bg-black text-white rounded-md px-3 py-1 text-center">
        <span>{text}</span>

        <span className="absolute bottom-0 -mb-1 bg-black transform rotate-45 w-2 h-2 left-1/2 -translate-x-1/2" />
      </span>
    </span>
  );
};

export default Tooltip;
