import { AxiosInstance } from 'axios';
import { QueryFunctionContext } from 'react-query';
import type { MonthlyReport } from '../types';

export function getReport(client: AxiosInstance) {
  return async ({
    queryKey: [_, month, year, location],
  }: QueryFunctionContext<
    [string, number, number, string | undefined]
  >): Promise<MonthlyReport> => {
    const { data } = await client.get<MonthlyReport>(`stats/months/report`, {
      params: { month, year, location: location || undefined },
    });

    return data;
  };
}
