import { faEye, faNotes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useMemo } from 'react';
import Button from '../../../../../components/Input/Button';
import Checkbox from '../../../../../components/Input/Checkbox';
import Tooltip from '../../../../../components/Layout/Tooltip';
import PriorityIndicator from '../../../../../components/Misc/PriorityIndicator';
import { SourceMarker } from '../../../../../components/Misc/SourceMarker/SourceMarker';
import StatusIndicator from '../../../../../components/Misc/StatusIndicator';
import { formatMoney } from '../../../../../formatting/formatMoney';
import Cell from './Cell';
import Time from './Time';

type Props = {
  readonly id: string;
  readonly priority: number;
  readonly selected: boolean;
  readonly selectable: boolean;
  readonly source: string;
  readonly axaptaOrderId: string | null;
  readonly axaptaOrderIdReceivedAt: string | null;
  readonly billingName: string;
  readonly status: string;
  readonly reason?: string;
  readonly orderDate: string;
  readonly lastUpdated: string;
  readonly value: number | null;
  readonly noteCount: number;
  readonly onInspect: () => void;
  readonly onSelect: () => void;
  readonly onDeselect: () => void;
  readonly onViewNotes: () => void;
  readonly onViewStatus: () => void;
};

const theme = ({ selected }: Partial<Props>) =>
  classNames({
    'hover:bg-yellow-50': !selected,
    'bg-ui-100 hover:bg-yellow-100': selected,
  });

export const Row = ({
  id,
  priority,
  source,
  axaptaOrderId,
  axaptaOrderIdReceivedAt,
  reason,
  selected,
  selectable,
  billingName,
  status,
  orderDate,
  lastUpdated,
  value,
  noteCount,
  onInspect,
  onSelect,
  onDeselect,
  onViewNotes,
  onViewStatus,
}: Props) => {
  const formattedValue = value ? formatMoney(value) : null;

  const formattedAxaptaOrderReceivedAt = useMemo(() => {
    if (axaptaOrderIdReceivedAt) {
      return format(new Date(axaptaOrderIdReceivedAt), 'dd MMM yy h:mma');
    }

    return 'unknown';
  }, [axaptaOrderIdReceivedAt]);

  return (
    <tr className={theme({ selected })}>
      <Cell small>
        <Checkbox
          disabled={!selectable}
          value={selected}
          onChange={(value) => {
            if (value) onSelect();
            else onDeselect();
          }}
        />
      </Cell>
      <Cell small>
        <PriorityIndicator priority={priority} onChange={() => {}} />
      </Cell>
      <Cell small>
        <SourceMarker source={source as any} />
      </Cell>
      <Cell>
        <button
          className="text-primary hover:text-primary-light"
          onClick={(event) => {
            event.preventDefault();
            onInspect();
          }}
        >
          <FontAwesomeIcon className="mr-2" icon={faEye} /> {id}
        </button>
      </Cell>
      <Cell>
        {axaptaOrderId ? (
          <Tooltip text={formattedAxaptaOrderReceivedAt}>
            {axaptaOrderId}
          </Tooltip>
        ) : (
          '-'
        )}
      </Cell>
      <Cell>
        <Button
          counter={noteCount}
          onClick={() => onViewNotes()}
          icon={faNotes}
        />
      </Cell>
      <Cell>{billingName}</Cell>
      <Cell>
        <Time value={orderDate} />
      </Cell>
      <Cell>
        <Time value={lastUpdated} />
      </Cell>
      <Cell right>{formattedValue ?? '-'}</Cell>
      <Cell small>
        <StatusIndicator
          status={status as any}
          reason={reason}
          onClick={onViewStatus}
        />
      </Cell>
    </tr>
  );
};

export default Row;
