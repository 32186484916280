import {
  faBagShopping,
  faStickyNote,
  faTimelineArrow,
  faTruck,
  faUndo,
} from '@fortawesome/pro-light-svg-icons';
import NavigationItem from './components/NavigationItem';

type Props = {
  readonly orderId: number;
  readonly noteCount: number;
  readonly hasShipment: boolean;
  readonly hasReturn: boolean;
  readonly canReturn: boolean;
};

export const Navigation = ({
  orderId,
  noteCount,
  hasShipment,
  hasReturn,
  canReturn,
}: Props) => {
  return (
    <div className="py-5">
      <NavigationItem
        orderId={orderId}
        text="Order Details"
        icon={faBagShopping}
        path=""
      />
      <NavigationItem
        orderId={orderId}
        text="Status History"
        icon={faTimelineArrow}
        path="status"
      />
      <NavigationItem
        orderId={orderId}
        text="Notes"
        icon={faStickyNote}
        path="notes"
        counter={noteCount}
      />
      <NavigationItem
        orderId={orderId}
        text="Shipments"
        icon={faTruck}
        path="shipment"
        done={hasShipment}
      />
      {canReturn && (
        <NavigationItem
          orderId={orderId}
          text="Returns"
          icon={faUndo}
          path="return"
          done={hasReturn}
        />
      )}
    </div>
  );
};
