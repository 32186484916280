import { AxiosInstance } from 'axios';

type Response = {
  readonly id: string;
  readonly sourceIdentifier: string;
  readonly sourceReference: string;
  readonly sourceName: string;
};

type Variables = {
  readonly orderId: number;
};

export function pushOrder(client: AxiosInstance) {
  return async (variables: Variables) => {
    const { data } = await client.post<Response>(
      `orders/${variables.orderId}/push-ax`,
    );

    return data;
  };
}
