import { ReactNode } from 'react';
import Heading from './Heading';

type Props = {
  readonly children: ReactNode;
  readonly heading: string;
};

const Section = ({ heading, children }: Props) => {
  return (
    <section>
      <Heading text={heading} />
      <div className="mb-10">{children}</div>
    </section>
  );
};

export default Section;
