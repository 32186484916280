import { faEye, faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { useState } from 'react';
import { ServerError } from '../../../../../../api/error';
import {
  OrderStatus,
  OrderStatusChange,
  OrderStatusChangeReason,
} from '../../../../../../api/types';
import Button from '../../../../../../components/Input/Button';
import DataTable from '../../../../../../components/Layout/DataTable';
import InlineUser from '../../../../../../components/Misc/InlineUser';
import StatusIndicator from '../../../../../../components/Misc/StatusIndicator';
import Heading from '../../components/Heading';
import SplitPanel from '../../components/SplitPanel';
import Form from './components/Form';
import StatusChangeModal from './components/StatusChangeModal';

type Props = {
  readonly orderId: number;
  readonly onUpdate: () => Promise<any>;
  readonly onError: (error: ServerError | null) => void;
  readonly statuses: readonly OrderStatus[];
  readonly reasons: readonly OrderStatusChangeReason[];
  readonly changes: readonly OrderStatusChange[];
  readonly canPrint: boolean;
};

export const StatusView = ({
  orderId,
  onUpdate,
  onError,
  statuses,
  reasons,
  changes,
  canPrint,
}: Props) => {
  const [selectedChange, setSelectedChange] =
    useState<OrderStatusChange | null>(null);

  return (
    <>
      <SplitPanel
        footer={
          <Form
            orderId={orderId}
            onUpdate={onUpdate}
            onError={onError}
            statuses={statuses}
            reasons={reasons}
            canPrint={canPrint}
          />
        }
      >
        <Heading text="Status History" />

        <DataTable
          headings={[
            'Status',
            'Date',
            <FontAwesomeIcon icon={faUser} />,
            'Reason',
            '',
          ]}
          rows={changes.map((change) => ({
            key: change.id,
            values: [
              <StatusIndicator status={change.status.handle as any} />,
              format(new Date(change.created), 'do MMM yyyy hh:mma'),
              change.user ? (
                <InlineUser user={change.user} />
              ) : change.meta?.origin === 'axapta' ? (
                <>Axapta</>
              ) : (
                '~'
              ),
              change.reason ? (
                <span
                  className="font-mono text-sm"
                  title={change.reason.description}
                >
                  {change.reason.code}
                </span>
              ) : (
                '~'
              ),
              <Button onClick={() => setSelectedChange(change)} icon={faEye} />,
            ],
          }))}
        />
      </SplitPanel>
      {selectedChange && (
        <StatusChangeModal
          change={selectedChange}
          onClose={() => setSelectedChange(null)}
        />
      )}
    </>
  );
};
