import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useClient } from '../../../../../api';
import Button from '../../../../../components/Input/Button';
import TextInput from '../../../../../components/Input/TextInput';

type Props = {
  readonly onUpdate: () => void;
};

const Form = ({ onUpdate }: Props) => {
  const [name, setName] = useState('');
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');

  const { createPackingPreset } = useClient();
  const { mutate, isLoading } = useMutation(createPackingPreset, {
    onSuccess: () => onUpdate(),
  });

  const canSubmit = name && length && width && height;

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();

        mutate({
          name,
          width: parseInt(width),
          length: parseInt(length),
          height: parseInt(height),
          weight: weight ? parseFloat(weight) * 1000 : undefined,
        });
      }}
    >
      <div className="grid grid-cols-3 gap-2 flex-grow">
        <div className="col-span-2">
          <TextInput
            placeholder="Name"
            value={name}
            onChange={(value) => setName(value)}
          />
        </div>
        <div>
          <TextInput
            placeholder="Weight (kg)"
            value={weight}
            onChange={(value) => setWeight(value)}
          />
        </div>
        <div>
          <TextInput
            placeholder="Length (cm)"
            value={length}
            onChange={(value) => setLength(value)}
          />
        </div>
        <div>
          <TextInput
            placeholder="Width (cm)"
            value={width}
            onChange={(value) => setWidth(value)}
          />
        </div>
        <div>
          <TextInput
            placeholder="Height (cm)"
            value={height}
            onChange={(value) => setHeight(value)}
          />
        </div>
        <div className="col-span-3">
          <Button disabled={!canSubmit} loading={isLoading} type="submit">
            Add Preset <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Form;
