import { AxiosInstance } from 'axios';
import { CreateOrderDocumentModel, OrderDocument } from '../types';

type Variables = readonly CreateOrderDocumentModel[];

export function createDocuments(client: AxiosInstance) {
  return async (variables: Variables) => {
    const { data } = await client.post<readonly OrderDocument[]>('documents', {
      documents: variables,
    });

    return data;
  };
}
