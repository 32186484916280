import { ServerError } from '../../../../../api/error';

type Props = {
  readonly error: ServerError<any>;
};

const ErrorMessage = ({ error }: Props) => {
  const reasons: readonly string[] | undefined = error.data?.details?.map(
    (entry: { message: string }) => entry.message,
  );

  return (
    <div className="bg-red-500 text-red-50 p-5">
      <p>{error.message}</p>
      {reasons && reasons.length > 0 && (
        <ul className="mt-2 list-disc pl-6">
          {reasons.map((reason: string) => (
            <li key={reason}>{reason}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ErrorMessage;
