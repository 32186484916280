import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useClient } from '../../../../api';
import Button from '../../../../components/Input/Button';
import Select from '../../../../components/Input/Select';
import TextInput from '../../../../components/Input/TextInput';
import Container from '../../../../components/Layout/Container';
import { SERVER_URL } from '../../../../config';
import LineItem from './components/LineItem';

type Props = {
  //
};

export function SKUDetailsView({}: Props) {
  const { getAccessTokenSilently } = useAuth0();
  const [downloadLink, setDownloadLink] = useState('');

  const [query, setQuery] = useState('');

  const [sku, setSku] = useState('');
  const [binLocation, setBinLocation] = useState('');
  const [location, setLocation] = useState('');
  const [barcode, setBarcode] = useState('');

  const { searchSkus, upsertSku } = useClient();
  const { data } = useQuery(['skus', query], searchSkus);

  const { mutate, isLoading } = useMutation(upsertSku, {
    //
  });

  useEffect(() => {
    getAccessTokenSilently().then((token) =>
      setDownloadLink(SERVER_URL + `/data-export/skus?token=${token}`),
    );
  }, []);

  return (
    <div className="p-5">
      <Container>
        <div className="p-5">
          <div className="flex items-center space-x-3">
            <div className="flex-grow">
              <TextInput
                placeholder="Search SKU..."
                value={query}
                onChange={setQuery}
              />
            </div>
            <div className="flex-shrink-0">
              <Button href={downloadLink}>Download All</Button>
            </div>
          </div>

          <table className="mt-5 w-full">
            <thead>
              <tr>
                <th className="text-left p-1">SKU</th>
                <th className="text-left p-1">Bin Location</th>
                <th className="text-left p-1">Location</th>
                <th className="text-left p-1">Barcode</th>
                <td width={0} />
              </tr>
            </thead>
            <tbody>
              {(data ?? []).map((entry) => (
                <LineItem key={entry.sku} value={entry} onSave={() => {}} />
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td className="p-1">
                  <TextInput placeholder="SKU" value={sku} onChange={setSku} />
                </td>
                <td className="p-1">
                  <TextInput
                    placeholder="Bin Location"
                    value={binLocation}
                    onChange={setBinLocation}
                  />
                </td>
                <td className="p-1">
                  <Select
                    value={location}
                    onChange={setLocation}
                    options={[
                      {
                        value: 'frenchs-forest',
                        label: 'Frenchs Forest',
                      },
                      {
                        value: 'huntingwood',
                        label: 'Huntingwood',
                      },
                    ]}
                  />
                </td>
                <td className="p-1">
                  <TextInput
                    placeholder="Barcode"
                    value={barcode}
                    onChange={setBarcode}
                  />
                </td>
                <td className="p-1">
                  <Button
                    loading={isLoading}
                    onClick={() =>
                      mutate({
                        sku,
                        binLocation,
                        locationHandle: location,
                        barcode,
                      })
                    }
                  >
                    Add
                  </Button>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </Container>
    </div>
  );
}
