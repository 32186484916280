import classNames from 'classnames';
import { MonthlyReport, MonthlySourceReport } from '../../../api/types';
import { formatDuration } from '../../../formatting/formatDuration';
import { formatMoney } from '../../../formatting/formatMoney';

type Props = {
  readonly heading: string;
  readonly subheading?: string;
  readonly report: MonthlyReport;
  readonly target: keyof MonthlySourceReport;
  readonly threshold?: keyof MonthlySourceReport;
  readonly totalThreshold?: number;
  readonly total: number;
  readonly format?: 'money' | 'duration';
};

const theme = (value: number, threshold?: number) =>
  classNames({
    'text-green-500': threshold !== undefined && value > threshold,
    'text-red-500': threshold !== undefined && value <= threshold,
  });

const Row = ({
  heading,
  subheading,
  report,
  target,
  threshold,
  format,
  total,
  totalThreshold,
}: Props) => {
  const formatValue = (value: number) => {
    return format === 'money'
      ? formatMoney(value)
      : format === 'duration'
        ? formatDuration(value)
        : value.toString();
  };

  return (
    <tr className="border-t border-ui-200 border-b last:border-b-0">
      <th className="text-left py-2">
        {heading}
        {subheading && (
          <>
            <br />
            <span className="font-medium text-ui-400 text-xs">
              {subheading}
            </span>
          </>
        )}
      </th>
      {report.sources.map((entry) => {
        const value = parseFloat(entry[target].toString());
        const thresholdValue = threshold
          ? parseFloat(entry[threshold].toString())
          : undefined;

        return (
          <td key={entry.source}>
            <span className={theme(value, thresholdValue)}>
              {formatValue(value)}
            </span>
          </td>
        );
      })}
      <td>
        <span className={theme(total, totalThreshold)}>
          {formatValue(total)}
        </span>
      </td>
    </tr>
  );
};

export default Row;
