export function formatMoney(
  value: number,
  includeCents: boolean = true,
): string {
  return (value ? value / 100 : 0).toLocaleString('en-AU', {
    style: 'currency',
    currency: 'AUD',
    maximumFractionDigits: includeCents ? 2 : 0,
  });
}
