import classNames from 'classnames';
import { ReactNode } from 'react';

type Props = {
  readonly children: ReactNode;
  readonly small?: boolean;
  readonly right?: boolean;
};

const theme = ({ small, right }: Partial<Props>) =>
  classNames(
    'bg-transparent py-2 border-t border-ui-200 group-hover:bg-yellow-50 first:pl-5 last:pr-5',
    {
      'px-1': small,
      'px-3': !small,
      'text-right': right,
    },
  );

const Cell = ({ children, small, right }: Props) => {
  return (
    <td width={small ? 1 : undefined} className={theme({ small, right })}>
      {children}
    </td>
  );
};

export default Cell;
