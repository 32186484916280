import classNames from 'classnames';

type Props = {
  readonly view: string;
  readonly onChange: (value: string) => void;
};

const theme = (view: Props['view'], selected: boolean) =>
  classNames('inline-block px-5 py-3 font-semibold', {
    'rounded-l': view === 'summary',
    'rounded-r': view === 'budgets',
    'bg-white text-ui-900': selected,
    'bg-ui-300 hover:bg-100 text-ui-600': !selected,
  });

const Switcher = ({ view, onChange }: Props) => {
  return (
    <div>
      <button
        className={theme('summary', view === 'summary')}
        onClick={() => onChange('summary')}
      >
        Summary
      </button>
      <button
        className={theme('budgets', view === 'budgets')}
        onClick={() => onChange('budgets')}
      >
        Budgets
      </button>
    </div>
  );
};

export default Switcher;
