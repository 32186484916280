import { Report } from '../../../api/types';
import Row from './Row';

type Props = {
  readonly lines: readonly Report[];
};

const Summary = ({ lines }: Props) => {
  return (
    <>
      {lines.map((line) => (
        <Row
          key={line.source}
          source={line.source}
          values={[
            { value: line.activeCount },
            { value: line.activeDollars, money: true },
            { value: line.shippedTodayCount },
            { value: line.shippedTodayDollars, money: true },
          ]}
        />
      ))}
    </>
  );
};

export default Summary;
