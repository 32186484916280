import classNames from 'classnames';
import { formatMoney } from '../../../formatting/formatMoney';
import SourceImage from './SourceImage';

type Value = {
  readonly value: number;
  readonly money?: boolean;
  readonly sentiment?: 'good' | 'bad';
};

type Props = {
  readonly source: string;
  readonly values: readonly Value[];
};

const theme = ({ sentiment }: Partial<Value>) =>
  classNames('self-center w-full text-center text-xl font-semibold', {
    'text-green-500': sentiment === 'good',
    'text-red-500': sentiment === 'bad',
  });

const Row = ({ source, values }: Props) => {
  return (
    <div className="h-1/4 grid grid-cols-5 border-t border-ui-300 last:border-b">
      <div className="flex p-10">
        <span className="self-center">
          <SourceImage source={source} />
        </span>
      </div>
      {values.map(({ value, money, sentiment }, index) => (
        <div key={index} className="flex p-10">
          <span className={theme({ sentiment })}>
            {money ? formatMoney(value, false) : value.toLocaleString()}
          </span>
        </div>
      ))}
    </div>
  );
};

export default Row;
