import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { useClient } from '../../api';
import { Report } from '../../api/types';
import { formatMoney } from '../../formatting/formatMoney';
import Budgets from './components/Budgets';
import Summary from './components/Summary';
import Switcher from './components/Switcher';

type View = 'summary' | 'budgets';

export const SummaryView = () => {
  const [view, setView] = useState<View>('summary');

  const { getSummary } = useClient();
  const { data } = useQuery(['summary'], getSummary, {
    refetchInterval: 10000,
  });

  if (!data) {
    return (
      <div className="flex h-screen items-center text-center">
        <div className="w-full">
          <p>Loading summary&hellip;</p>
        </div>
      </div>
    );
  }

  const total = (field: keyof Report) =>
    data.reduce(
      (total: number, report: Report) =>
        total + parseFloat(report[field].toString()),
      0,
    );

  return (
    <>
      <Helmet>
        <title>Summary &middot; OMD</title>
      </Helmet>
      <div className="flex flex-col h-screen">
        <div className="flex-shrink-0 bg-ui-200">
          <div className="grid grid-cols-5 p-5">
            <Switcher
              view={view}
              onChange={(value) => setView(value as View)}
            />

            <div className="col-span-2 flex">
              <span className="self-center w-full text-center font-bold text-xl">
                {view === 'summary' ? 'Active Orders' : 'Month'}
              </span>
            </div>
            <div className="col-span-2 flex">
              <span className="self-center w-full text-center font-bold text-xl">
                {view === 'summary' ? 'Shipped Today' : 'Year'}
              </span>
            </div>
          </div>

          <div className="grid grid-cols-5 py-5 border-t border-ui-400 text-ui-600 font-semibold text-lg">
            <div />

            <div className="flex">
              <span className="self-center w-full text-center ">
                {view === 'summary' ? 'Total' : 'Budget'}
              </span>
            </div>
            <div className="flex">
              <span className="self-center w-full text-center">
                {view === 'summary' ? 'Value' : 'Actual'}
              </span>
            </div>
            <div className="flex">
              <span className="self-center w-full text-center">
                {view === 'summary' ? 'Total' : 'Budget'}
              </span>
            </div>
            <div className="flex">
              <span className="self-center w-full text-center">
                {view === 'summary' ? 'Value' : 'Actual'}
              </span>
            </div>
          </div>
        </div>

        <div className="flex-grow">
          {view === 'summary' && <Summary lines={data} />}
          {view === 'budgets' && <Budgets lines={data} />}
        </div>

        <div className="flex-shrink-0 bg-ui-100 grid grid-cols-5 py-10 font-bold text-xl">
          <div />

          <div className="flex">
            <span className="self-center w-full text-center">
              {view === 'summary'
                ? total('activeCount')
                : formatMoney(total('monthBudget'), false)}
            </span>
          </div>
          <div className="flex">
            <span className="self-center w-full text-center">
              {view === 'summary'
                ? formatMoney(total('activeDollars'), false)
                : formatMoney(total('monthActual'), false)}
            </span>
          </div>
          <div className="flex">
            <span className="self-center w-full text-center">
              {view === 'summary'
                ? total('shippedTodayCount')
                : formatMoney(total('yearBudget'), false)}
            </span>
          </div>
          <div className="flex">
            <span className="self-center w-full text-center">
              {view === 'summary'
                ? formatMoney(total('shippedTodayDollars'), false)
                : formatMoney(total('yearActual'), false)}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
