import { AxiosInstance } from 'axios';
import { PackingPreset } from '../types';

type Variables = {
  readonly name: string;
  readonly length: number;
  readonly width: number;
  readonly height: number;
  readonly weight?: number;
};

export function createPackingPreset(client: AxiosInstance) {
  return async (variables: Variables) => {
    return await client.post<PackingPreset>('packing-presets', variables);
  };
}
