import useLocalStorage from 'react-use-localstorage';

export type Settings = {
  readonly navExpanded: boolean;
  readonly packingPresetId?: string;
};

const initial: Settings = {
  navExpanded: true,
  packingPresetId: undefined,
};

export function useSettings(): [Settings, (value: Partial<Settings>) => void] {
  const [value, setValue] = useLocalStorage(
    'settings',
    JSON.stringify(initial),
  );

  const settings = JSON.parse(value);

  return [
    settings,
    (value: Partial<Settings>) =>
      setValue(JSON.stringify({ ...settings, ...value })),
  ];
}
