import { AxiosInstance } from 'axios';

type Variables = {
  readonly id: number;
};

export function setPackingPresetDefault(client: AxiosInstance) {
  return async (variables: Variables) => {
    const { data } = await client.patch<void>(
      `packing-presets/${variables.id}/set-default`,
    );

    return data;
  };
}
