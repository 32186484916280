import { ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  readonly children: ReactNode;
};

const Mount = ({ children }: Props) => {
  const el = useRef(document.createElement('div'));

  useEffect(() => {
    const mount = el.current;

    document.body.appendChild(mount);

    return () => {
      document.body.removeChild(mount);
    };
  }, []);

  return createPortal(children, el.current);
};

export default Mount;
