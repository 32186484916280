import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

type Props = {
  readonly value: boolean;
  readonly hint?: string;
  readonly disabled?: boolean;
  readonly onChange: (value: boolean) => void;
};

const theme = ({ value, disabled }: Partial<Props>) =>
  classNames('w-8 h-8 leading-8 rounded text-center', {
    'bg-ui-100 text-ui-300 hover:bg-ui-200 hover:text-ui-400':
      !value && !disabled,
    'bg-ui-600 hover:bg-ui-700 text-white': value && !disabled,
    'bg-white text-ui-200 cursor-not-allowed': disabled,
  });

const Checkbox = ({ value, hint, disabled = false, onChange }: Props) => {
  return (
    <button
      disabled={disabled}
      title={hint}
      className={theme({ value, disabled })}
      onClick={(event) => {
        event.preventDefault();
        onChange(!value);
      }}
    >
      <FontAwesomeIcon icon={faCheck} />
    </button>
  );
};

export default Checkbox;
