import { useAuth0 } from '@auth0/auth0-react';
import { faSignOutAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { User } from '../../../../api/types';
import { useMemo } from 'react';

type Props = {
  readonly expanded: boolean;
  readonly permissions: User['permissions'];
};

const theme = (expanded: boolean) =>
  classNames('py-5 border-t border-ui-800', {
    'px-5': expanded,
    // 'px-2': !expanded,
  });

const Profile = ({ expanded, permissions }: Props) => {
  const { user, logout } = useAuth0();

  const locations = useMemo(() => {
    const result: string[] = [];

    if (permissions.includes('read:orders:huntingwood')) {
      result.push('HW');
    }

    if (permissions.includes('read:orders:frenchs-forest')) {
      result.push('FF');
    }

    return result;
  }, [permissions]);

  return (
    <div className={theme(expanded)}>
      {user && (
        <div className="flex overflow-hidden w-full">
          <div className="block relative mx-auto justify-self-center flex-shrink-0 self-center bg-ui-600 w-10 h-10 rounded-full overflow-hidden shadow">
            <figure>{user.picture && <img src={user.picture} alt="" />}</figure>

            <button
              className="absolute bg-ui-900 bg-opacity-50 opacity-0 hover:opacity-100 w-full flex inset-0 text text-white"
              onClick={() =>
                logout({
                  logoutParams: {
                    returnTo: window.location.origin,
                  },
                })
              }
            >
              <FontAwesomeIcon
                className="self-center mx-auto"
                icon={faSignOutAlt}
              />
            </button>
          </div>
          {expanded && (
            <div className="self-center overflow-hidden pl-2">
              <p className="text-sm font-semibold truncate text-ui-200">
                {user.name}
              </p>
              <p className="text-xs text-ui-500 truncate" title={user.email}>
                {user.email}
              </p>
              <div>
                {locations.map((loc) => (
                  <span
                    key={loc}
                    className="text-xs rounded-md px-1 inline-block bg-ui-700 text-white mr-2"
                  >
                    {loc}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Profile;
