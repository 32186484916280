import { faVial } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import AlessiIcon from './fixtures/alessi.png';
import DavidJonesIcon from './fixtures/david-jones.png';
import GeorgJensenIcon from './fixtures/georg-jensen.png';
import IconicIcon from './fixtures/iconic.png';

type Props = {
  readonly large?: boolean;
  readonly source:
    | 'sandbox'
    | 'alessi'
    | 'georgJensen'
    | 'davidJones'
    | 'iconic';
};

export const names: { [key in Props['source']]: string } = {
  sandbox: 'Sandbox',
  alessi: 'Alessi',
  georgJensen: 'Georg Jensen',
  davidJones: 'David Jones',
  iconic: 'The Iconic',
};

const theme = ({ source, large }: Partial<Props>) =>
  classNames(
    'inline-block text-center rounded overflow-hidden bg-white align-middle',
    {
      'w-8 h-8 leading-8': !large,
      'w-10 h-10 leading-10': large,
      'bg-purple-500 text-white': source === 'sandbox',
    },
  );

export const SourceMarker = ({ source, large }: Props) => {
  const name = names[source];

  return (
    <span className={theme({ source, large })} title={name}>
      {source === 'sandbox' && <FontAwesomeIcon icon={faVial} />}
      {source === 'georgJensen' && <img src={GeorgJensenIcon} alt={name} />}
      {source === 'davidJones' && <img src={DavidJonesIcon} alt={name} />}
      {source === 'iconic' && <img src={IconicIcon} alt={name} />}
      {source === 'alessi' && <img src={AlessiIcon} alt={name} />}
    </span>
  );
};
