import { AxiosInstance } from 'axios';
import { QueryFunctionContext } from 'react-query';
import { YearMonth } from '../types';

export function getOrderMonths(client: AxiosInstance) {
  return async ({
    queryKey,
  }: QueryFunctionContext<[string]>): Promise<readonly YearMonth[]> => {
    const { data } = await client.get<readonly YearMonth[]>(`stats/months`);

    return data;
  };
}
