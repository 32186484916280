import { ReactNode } from 'react';

type Props = {
  readonly children: ReactNode;
};

const Container = ({ children }: Props) => {
  return (
    <section className="relative overflow-hidden bg-white rounded shadow-lg">
      {children}
    </section>
  );
};

export default Container;
