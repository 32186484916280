import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-light-svg-icons';
import TextInput from '../../../../../../../components/Input/TextInput';
import Field from '../../../../../../../components/Input/Field';
import Select from '../../../../../../../components/Input/Select';
import Button from '../../../../../../../components/Input/Button';
import { useClient } from '../../../../../../../api';
import { useMutation } from 'react-query';

type Props = {
  readonly id?: number;
  readonly name?: string;
  readonly address1?: string;
  readonly address2?: string;
  readonly suburb?: string;
  readonly state?: string;
  readonly postcode?: string;
  readonly country?: string;
  readonly onUpdate: () => Promise<any>;
};

const Address = ({
  id,
  name,
  address1,
  address2,
  suburb,
  state,
  postcode,
  country,
  onUpdate,
}: Props) => {
  const { updateAddress } = useClient();

  const [editing, setEditing] = useState(false);

  const [updatedLine1, setUpdatedLine1] = useState(address1 ?? '');
  const [updatedLine2, setUpdatedLine2] = useState(address2 ?? '');
  const [updatedSuburb, setUpdatedSuburb] = useState(suburb ?? '');
  const [updatedState, setUpdatedState] = useState(state ?? '');
  const [updatedPostcode, setUpdatedPostcode] = useState(postcode ?? '');

  const { mutate, isLoading } = useMutation(updateAddress, {
    onSuccess: async () => {
      await onUpdate();
      setEditing(false);
    },
  });

  const update = async () => {
    if (id) {
      mutate({
        id: id ?? 0,
        model: {
          addressLine1: updatedLine1,
          addressLine2: updatedLine2,
          suburb: updatedSuburb,
          state: updatedState,
          postcode: updatedPostcode,
        },
      });
    }
  };

  return (
    <div className="relative bg-ui-100 p-5 rounded" data-id={id}>
      {id && (
        <button
          onClick={() => setEditing(!editing)}
          className="absolute top-0 right-0 p-2 text-sm text-slate-500 hover:text-slate-700"
        >
          <FontAwesomeIcon icon={faPencil} />
        </button>
      )}

      {editing ? (
        <>
          {name && <div className="font-bold">{name}</div>}
          <div className="grid gap-2 grid-cols-4 mb-2">
            <Field label="Address Line 1" className="col-span-2">
              <TextInput
                value={updatedLine1}
                onChange={setUpdatedLine1}
                placeholder="Address Line 1"
              />
            </Field>
            <Field label="Address Line 2" className="col-span-2">
              <TextInput
                value={updatedLine2}
                onChange={setUpdatedLine2}
                placeholder="Address Line 2"
              />
            </Field>
            <Field className="col-span-2" label="Suburb">
              <TextInput
                value={updatedSuburb}
                onChange={setUpdatedSuburb}
                placeholder="Suburb"
              />
            </Field>
            <Field label="State">
              <Select
                placeholder="State"
                value={updatedState}
                onChange={setUpdatedState}
                options={[
                  { value: 'ACT' },
                  { value: 'NSW' },
                  { value: 'NT' },
                  { value: 'QLD' },
                  { value: 'SA' },
                  { value: 'TAS' },
                  { value: 'VIC' },
                  { value: 'WA' },
                ]}
              />
            </Field>
            <Field label="Postcode">
              <TextInput
                value={updatedPostcode}
                onChange={setUpdatedPostcode}
                placeholder="Postcode"
              />
            </Field>
          </div>
          <Button loading={isLoading} onClick={() => update()}>
            Update
          </Button>
        </>
      ) : (
        <>
          {name && <div className="font-bold">{name}</div>}
          <div>
            {address1}
            <br />
            {address2}
            <br />
            {suburb} {state} {postcode}
            <br />
            {country}
          </div>
        </>
      )}
    </div>
  );
};

export default Address;
