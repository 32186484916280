import {
  faCalendarPen,
  faCalendarPlus,
  faHashtag,
  faUser,
} from '@fortawesome/pro-light-svg-icons';
import { format } from 'date-fns';
import { FC, useState } from 'react';
import { useClient } from '../../../../../../api';
import { Order, User } from '../../../../../../api/types';
import Button from '../../../../../../components/Input/Button';
import Item from './components/Item';

type Props = {
  readonly permissions: User['permissions'];
  readonly order: Order;
};

export const Summary: FC<Props> = ({ permissions, order }) => {
  const [loading, setLoading] = useState(false);

  const formattedCreated = format(new Date(order.created), 'dd MMM yy h:mma');
  const formattedUpdated = format(
    new Date(order.statusChanges[0].created),
    'dd MMM yy h:mma',
  );

  const { pushOrder } = useClient();

  const handlePushOrder = async () => {
    setLoading(true);

    try {
      await pushOrder({ orderId: order.id });

      window.alert('Order has been pushed to Axapta');
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  return (
    <aside>
      <Item label="Customer" icon={faUser}>
        <a
          className="text-blue-800 hover:text-blue-600"
          href={`mailto:${order.customer.email}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          {order.customer.firstName} {order.customer.lastName}
        </a>
      </Item>
      <Item label="Captured" icon={faCalendarPlus}>
        {formattedCreated}
      </Item>
      <Item label="Last Updated" icon={faCalendarPen}>
        {formattedUpdated}
      </Item>
      {order.source.forwardToAxapta && (
        <Item label="Axapta ID" icon={faHashtag}>
          {order.axaptaOrderId ? (
            order.axaptaOrderId
          ) : permissions.includes('modify:settings') ? (
            <Button loading={loading} onClick={() => handlePushOrder()}>
              Resend
            </Button>
          ) : (
            '~'
          )}
        </Item>
      )}
    </aside>
  );
};
