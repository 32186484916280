import { useQuery } from 'react-query';
import { useClient } from '../../../../api';
import { SearchOrdersCriteria } from '../../../../api/types';
import Count from './components/Count';
import Search from './components/Search';

type Props = {
  readonly criteria: Partial<SearchOrdersCriteria>;
  readonly onChange: (criteria: Partial<SearchOrdersCriteria>) => void;
};

export const OrderListOverview = ({ criteria, onChange }: Props) => {
  const { getCounts } = useClient();
  const { data } = useQuery(
    ['counts', ['new', 'pending', 'printed', 'ready']],
    getCounts,
    {
      refetchInterval: 10000,
    },
  );

  const newStatus = data && data.find((count) => count.status === 'new');
  const readyStatus = data && data.find((count) => count.status === 'ready');
  const pendingStatus =
    data && data.find((count) => count.status === 'pending');
  const printedStatus =
    data && data.find((count) => count.status === 'printed');

  return (
    <div className="flex">
      <div className="self-center flex-grow">
        <Search onChange={(query) => onChange({ query })} />
      </div>
      <div className="flex-shrink-0 pl-10">
        <div className="self-center flex-grow grid grid-cols-3 gap-2 max-w-md">
          <Count
            theme="in-progress"
            heading="In Progress"
            count={
              pendingStatus && printedStatus
                ? pendingStatus.count + printedStatus.count
                : undefined
            }
          />
          <Count
            theme="new"
            heading="New"
            count={
              newStatus && readyStatus
                ? newStatus.count + readyStatus.count
                : undefined
            }
          />
          <Count
            theme="pending"
            heading="Pending"
            count={pendingStatus?.count}
          />
        </div>
      </div>
    </div>
  );
};
