import { AxiosInstance } from 'axios';
import { DataDownloadOptions } from '../types';

export function getExportUrl(
  client: AxiosInstance,
  tokenProvider: () => Promise<string>,
) {
  return async (options: DataDownloadOptions): Promise<string> => {
    return client.getUri({
      url: client.defaults.baseURL!.replace(/\/+$/, '') + '/data-export',
      params: {
        token: await tokenProvider(),
        ...options,
      },
    });
  };
}
