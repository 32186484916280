import { useState } from 'react';
import { useMutation } from 'react-query';
import { useClient } from '../../../../../../../api';
import Button from '../../../../../../../components/Input/Button';
import Field from '../../../../../../../components/Input/Field';
import Textarea from '../../../../../../../components/Input/Textarea';

type Props = {
  readonly orderId: number;
  readonly onUpdate: () => Promise<void>;
};

const Form = ({ orderId, onUpdate }: Props) => {
  const [note, setNote] = useState('');

  const { addNote } = useClient();
  const { mutate, isLoading } = useMutation(addNote, {
    onSuccess: () => {
      setNote('');

      return onUpdate();
    },
  });

  const handleSubmit = () => {
    mutate({
      id: orderId,
      body: note,
    });
  };

  const canSubmit = note.length > 0;

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      <div className="grid gap-5">
        <div>
          <Field label="Note">
            <Textarea
              placeholder="Enter order notes..."
              value={note}
              onChange={(value) => setNote(value)}
              rows={2}
            />
          </Field>
        </div>
        <div>
          <Button disabled={!canSubmit} loading={isLoading} type="submit">
            Add Note
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Form;
