import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowRightArrowLeft,
  faArrowRotateLeft,
  faBan,
  faBell,
  faCheck,
  faCircle,
  faCirclePause,
  faMoneyCheckDollar,
  faPrint,
  faTruckFast,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

type Props = {
  readonly status:
    | 'cancelled'
    | 'invoiced'
    | 'new'
    | 'ready'
    | 'pending'
    | 'printed'
    | 'refunded'
    | 'shipped'
    | 'exchanged';
  readonly reason?: string;
  readonly onClick?: () => void;
};

type Ref<T> = { readonly [key in Props['status']]: T };

export const names: Ref<string> = {
  cancelled: 'Cancelled',
  invoiced: 'Invoiced',
  new: 'New',
  ready: 'Ready',
  pending: 'Pending',
  printed: 'Printed',
  refunded: 'Refunded',
  shipped: 'Shipped',
  exchanged: 'Exchanged',
};

export const icons: Ref<IconProp> = {
  cancelled: faBan,
  invoiced: faMoneyCheckDollar,
  new: faCircle,
  ready: faCheck,
  pending: faCirclePause,
  printed: faPrint,
  refunded: faArrowRotateLeft,
  shipped: faTruckFast,
  exchanged: faArrowRightArrowLeft,
};

const styledStatuses: readonly Props['status'][] = [
  'new',
  'printed',
  'pending',
  'ready',
];

const theme = (status: Props['status'], interactive: boolean) =>
  classNames('group relative h-8 w-40 rounded flex', {
    'cursor-default': !interactive,
    'bg-gray-200 text-gray-500': status && !styledStatuses.includes(status),
    'bg-green-400 text-white': status === 'new',
    'bg-green-600 text-white': status === 'ready',
    'bg-blue-400 text-white': status === 'printed',
    'bg-yellow-400 text-white': status === 'pending',
  });

const iconTheme = (status: Props['status']) =>
  classNames('flex-shrink h-full self-center px-2 bg-black flex rounded-l', {
    'bg-gray-300 text-gray-400': status && !styledStatuses.includes(status),
    'bg-green-500 text-green-200': status === 'new',
    'bg-green-700 text-green-300': status === 'ready',
    'bg-blue-500 text-blue-200': status === 'printed',
    'bg-yellow-500 text-yellow-200': status === 'pending',
  });

const StatusIndicator = ({ status, reason, onClick }: Props) => {
  const name = names[status] ?? status;

  return (
    <button
      className={theme(status, !!onClick)}
      onClick={(event) => {
        event.preventDefault();
        onClick && onClick();
      }}
    >
      <span className={iconTheme(status)}>
        <FontAwesomeIcon className="self-center" icon={icons[status]} />
      </span>
      <span className="flex-grow self-center text-left pl-2 font-medium">
        {name}
      </span>

      {reason && (
        <span className="absolute bg-gray-600 text-white h-5 leading-5 w-5 group-hover:w-auto text-center top-0 right-0 text-xs rounded-full -mt-1 -mr-1 shadow">
          <span className="hidden group-hover:inline px-2 whitespace-nowrap">
            {reason}
          </span>
          <FontAwesomeIcon
            className="inline group-hover:hidden text-xs text-gray-300"
            icon={faBell}
          />
        </span>
      )}
    </button>
  );
};

export default StatusIndicator;
