import { useState } from 'react';
import { useMutation } from 'react-query';
import { useClient } from '../../../../../../../api';
import { OrderStatusHandle } from '../../../../../../../api/enums';
import { ServerError } from '../../../../../../../api/error';
import {
  OrderStatus,
  OrderStatusChangeReason,
} from '../../../../../../../api/types';
import Button from '../../../../../../../components/Input/Button';
import Field from '../../../../../../../components/Input/Field';
import Select from '../../../../../../../components/Input/Select';
import TextInput from '../../../../../../../components/Input/TextInput';
import Textarea from '../../../../../../../components/Input/Textarea';

type Props = {
  readonly orderId: number;
  readonly onUpdate: () => Promise<any>;
  readonly onError: (error: ServerError | null) => void;
  readonly statuses: readonly OrderStatus[];
  readonly reasons: readonly OrderStatusChangeReason[];
  readonly canPrint: boolean;
};

const Form = ({
  orderId,
  onUpdate,
  onError,
  statuses,
  reasons,
  canPrint,
}: Props) => {
  const [status, setStatus] = useState('');
  const [reason, setReason] = useState('');
  const [message, setMessage] = useState('');
  const [consignment, setConsignment] = useState('');

  const { updateStatus } = useClient();
  const { mutate, isLoading } = useMutation(updateStatus, {
    onMutate: () => onError(null),
    onSuccess: () => {
      setStatus('');
      setReason('');
      setMessage('');
      setConsignment('');

      return onUpdate();
    },
    onError: (error) => onError(error as ServerError),
  });

  const showConsignmentField = status === OrderStatusHandle.Shipped;
  const canSubmit = status.length > 0;

  const handleSubmit = () => {
    mutate({
      id: orderId,
      status,
      reason: reason ? parseInt(reason) : undefined,
      message,
      consignment: showConsignmentField ? consignment : undefined,
    });
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      <div className="grid grid-cols-2 gap-5">
        <div>
          <Field label="Status">
            <Select
              placeholder="Select Status"
              value={status}
              onChange={(value) => setStatus(value)}
              options={statuses.map((status) => ({
                label: status.name,
                value: status.handle,
                disabled: status.handle === 'printed' && !canPrint,
              }))}
            />
          </Field>
        </div>
        <div>
          <Field label="Reason">
            <Select
              placeholder="None"
              value={reason}
              onChange={(value) => setReason(value)}
              options={reasons.map((reason) => ({
                label: reason.description,
                value: reason.id.toString(),
              }))}
            />
          </Field>
        </div>
        {showConsignmentField && (
          <div className="col-span-2">
            <Field label="Consignment Number">
              <TextInput
                placeholder="ABC123XXX"
                value={consignment}
                onChange={(value) => setConsignment(value)}
              />
            </Field>
          </div>
        )}
        <div className="col-span-2">
          <Field label="Message">
            <Textarea
              placeholder="Optional message or notes..."
              value={message}
              onChange={(value) => setMessage(value)}
              rows={2}
            />
          </Field>
        </div>
        <div>
          <Button disabled={!canSubmit} loading={isLoading} type="submit">
            Update Status
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Form;
