import { AxiosInstance } from 'axios';
import { Order } from '../types';

type Variables = {
  readonly id: number;
  readonly model: {
    readonly locations: readonly Order['locationRefs'][0]['location']['handle'][];
  };
};

type Response = readonly {
  readonly orderId: number;
  readonly locationId: number;
}[];

export function assignOrderLocations(client: AxiosInstance) {
  return async (variables: Variables) => {
    const { data } = await client.post<Response>(
      `orders/${variables.id}/assign-locations`,
      variables.model,
    );

    return data;
  };
}
