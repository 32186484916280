import classNames from 'classnames';

type Props = {
  readonly expanded: boolean;
};

const theme = (expanded: boolean) =>
  classNames('py-5', {
    'px-5': expanded,
    'text-center': !expanded,
  });

const Logo = ({ expanded }: Props) => {
  return <h1 className={theme(expanded)}>OMD</h1>;
};

export default Logo;
