import { AxiosInstance } from 'axios';

type Variables = {
  readonly ids: readonly number[];
};

export function updatePackingPresetOrder(client: AxiosInstance) {
  return async (variables: Variables) => {
    const { data } = await client.patch<void>(`packing-presets/order`, {
      ids: variables.ids,
    });

    return data;
  };
}
