import { faBox, faTag } from '@fortawesome/pro-light-svg-icons';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch } from 'react-router';
import Subnavigation from '../../components/Layout/Subnavigation';
import Group from '../../components/Layout/Subnavigation/components/Group';
import View from '../../components/Layout/View';
import PackagingPresetsView from './views/PackagingPresetsView';
import SKUDetailsView from './views/SKUDetailsView';

export const SettingsView = () => {
  return (
    <>
      <Helmet>
        <title>Settings &middot; OMD</title>
      </Helmet>
      <View
        scrollable={true}
        heading={
          <div className="grid grid-cols-3 gap-5">
            <div className="col-span-2 flex">
              <h2 className="text-lg font-bold tracking-wider self-center">
                System Settings
              </h2>
            </div>
          </div>
        }
        sidebar={
          <Subnavigation>
            <Group
              heading="Settings"
              links={[
                {
                  icon: faBox,
                  label: 'Packing Presets',
                  link: '/settings/packing-presets',
                },
                {
                  icon: faTag,
                  label: 'SKU Details',
                  link: '/settings/sku-details',
                },
              ]}
            />
          </Subnavigation>
        }
      >
        <Switch>
          <Route
            path="/settings"
            exact
            render={() => <Redirect to="/settings/packing-presets" />}
          />
          <Route
            path="/settings/packing-presets"
            render={() => <PackagingPresetsView />}
          />
          <Route
            path="/settings/sku-details"
            render={() => <SKUDetailsView />}
          />
        </Switch>
      </View>
    </>
  );
};
