import { format } from 'date-fns';

type Props = {
  readonly value: string;
};

export const Time = ({ value }: Props) => {
  const date = new Date(value);
  const formattedDate = format(date, 'do MMM yyyy');
  const formattedTime = format(date, 'h:mma');

  return (
    <time className="block text-ui-500" dateTime={date.toISOString()}>
      {formattedTime}
      <br />
      {formattedDate}
    </time>
  );
};

export default Time;
