import { useMutation } from 'react-query';
import { useClient } from '../../../../../../api';
import { OrderReturn } from '../../../../../../api/types';
import Button from '../../../../../../components/Input/Button';
import Heading from '../../components/Heading';
import SplitPanel from '../../components/SplitPanel';
import ReturnLineItem from './components/ReturnLineItem';

type Props = {
  readonly orderId: number;
  readonly returns: readonly OrderReturn[];
  readonly onUpdate: () => Promise<any>;
};

export const ReturnView = ({ orderId, returns, onUpdate }: Props) => {
  const { returnOrder } = useClient();

  const { mutate: returnOrderMutation, isLoading: returnOrderLoading } =
    useMutation(returnOrder, {
      onSuccess: () => onUpdate(),
    });

  return (
    <SplitPanel>
      <Heading text="Returns" />

      {returns.length === 0 ? (
        <Button
          loading={returnOrderLoading}
          onClick={() => returnOrderMutation({ orderId })}
        >
          Return Received
        </Button>
      ) : (
        <section>
          {returns.map((line) => (
            <ReturnLineItem
              key={line.id}
              id={line.id}
              created={new Date(line.created)}
              completed={line.completed ? new Date(line.completed) : null}
              type={line.type}
              onComplete={() => onUpdate()}
            />
          ))}
        </section>
      )}
    </SplitPanel>
  );
};
