import {
  faArrowTurnDownRight,
  faCheck,
  faStar,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { OrderDiscountInfo, OrderLineItem } from '../../../../../../api/types';
import DataTable, {
  Props as DataTableProps,
} from '../../../../../../components/Layout/DataTable';
import { formatMoney } from '../../../../../../formatting/formatMoney';
import { formatPercentage } from '../../../../../../formatting/formatPercentage';
import Heading from '../../components/Heading';
import SplitPanel from '../../components/SplitPanel';
import Address from './components/Address';
import GiftCardForm from './components/GiftCardForm';
import Line from './components/Line';
import { getLoyaltyTierName } from './support';

type Props = {
  readonly orderId: number;
  readonly supportsGiftCard: boolean;
  readonly billingAddressId?: number;
  readonly billingFirstName?: string;
  readonly billingLastName?: string;
  readonly billingAddress1?: string;
  readonly billingAddress2?: string;
  readonly billingSuburb?: string;
  readonly billingState?: string;
  readonly billingPostcode?: string;
  readonly billingCountry?: string;
  readonly shippingAddressId?: number;
  readonly shippingFirstName?: string;
  readonly shippingLastName?: string;
  readonly shippingAddress1?: string;
  readonly shippingAddress2?: string;
  readonly shippingSuburb?: string;
  readonly shippingState?: string;
  readonly shippingPostcode?: string;
  readonly shippingCountry?: string;
  readonly shippingMethod?: string;
  readonly total?: number;
  readonly subtotal?: number;
  readonly gst?: number;
  readonly shippingCost?: number;
  readonly paymentMethod?: string;
  readonly customerLoyaltyTier?: number;
  readonly email: string;
  readonly phone?: string;
  readonly lines: readonly OrderLineItem[];
  readonly customerNotes?: string;
  readonly giftMessage?: string;
  readonly discount?: OrderDiscountInfo | null;
  readonly onUpdate: () => Promise<any>;
};

export const DetailsView = ({
  orderId,
  supportsGiftCard,
  billingAddressId,
  billingFirstName,
  billingLastName,
  billingAddress1,
  billingAddress2,
  billingSuburb,
  billingState,
  billingPostcode,
  billingCountry,
  shippingAddressId,
  shippingFirstName,
  shippingLastName,
  shippingAddress1,
  shippingAddress2,
  shippingSuburb,
  shippingState,
  shippingPostcode,
  shippingCountry,
  shippingMethod,
  paymentMethod,
  customerLoyaltyTier,
  total,
  subtotal,
  gst,
  shippingCost,
  email,
  phone,
  lines,
  customerNotes,
  giftMessage,
  discount,
  onUpdate,
}: Props) => {
  const billingName = useMemo(
    () => [billingFirstName, billingLastName].join(' ').trim(),
    [billingFirstName, billingLastName],
  );
  const shippingName = useMemo(
    () => [shippingFirstName, shippingLastName].join(' ').trim(),
    [shippingFirstName, shippingLastName],
  );

  const giftWrappingTotalCost = useMemo(() => {
    return lines.reduce(
      (total, line) => total + (line.giftWrapCost ?? 0) * line.quantity,
      0,
    );
  }, [lines]);

  const lineItemRows = useMemo(() => {
    const rows: DataTableProps['rows'][0][] = [];

    for (const line of lines) {
      rows.push({
        key: line.id,
        values: [
          line.sku,
          <>
            {line.name ?? '~'}{' '}
            {line.children.length > 0 && (
              <>
                <span className="ml-2 bg-ui-200 text-ui-800 font-bold text-xs rounded-md inline-block uppercase px-2 py-1">
                  Bundle
                </span>
              </>
            )}
          </>,
          `x${line.quantity}`,
          line.giftWrap ? (
            <FontAwesomeIcon className="text-green-500" icon={faCheck} />
          ) : (
            <FontAwesomeIcon className="text-red-500" icon={faTimes} />
          ),
          line.itemCostPaid !== null && line.itemCostPaid < line.itemCost ? (
            <>
              <s>{formatMoney(line.itemCost * line.quantity)}</s>{' '}
              {formatMoney(line.itemCostPaid * line.quantity)}
            </>
          ) : (
            formatMoney(line.total)
          ),
        ],
      });

      for (const child of line.children) {
        rows.push({
          key: child.id,
          nested: true,
          values: [
            <>
              <FontAwesomeIcon
                className="mr-2 text-ui-400"
                icon={faArrowTurnDownRight}
              />{' '}
              {child.sku}
            </>,
            child.name ?? '~',
            `x${child.quantity}`,
            child.giftWrap ? (
              <FontAwesomeIcon className="text-green-500" icon={faCheck} />
            ) : (
              <FontAwesomeIcon className="text-red-500" icon={faTimes} />
            ),
            <>~</>,
          ],
        });
      }
    }

    return rows;
  }, [lines]);

  return (
    <SplitPanel>
      <Heading text="Billing & Shipping" />

      <div className="grid grid-cols-2 gap-5 mb-10">
        <div>
          <h6 className="text-ui-600 mb-2">Billing</h6>
          <div className="mb-5">
            <Address
              name={billingName}
              address1={billingAddress1}
              address2={billingAddress2}
              suburb={billingSuburb}
              state={billingState}
              postcode={billingPostcode}
              country={billingCountry}
              onUpdate={onUpdate}
            />
          </div>
          <Line label="Email">
            {email ? (
              <a
                className="text-blue-800 hover:text-blue-600"
                href={'mailto:' + email}
              >
                {email}
              </a>
            ) : (
              <>-</>
            )}
          </Line>
          <Line label="Phone">{phone ?? '-'}</Line>
          {(customerLoyaltyTier ?? 0) > 0 && (
            <Line label="Loyalty">
              <div>{getLoyaltyTierName(customerLoyaltyTier ?? 0)}</div>
              <FontAwesomeIcon className="text-xs ml-1" icon={faStar} />
            </Line>
          )}
        </div>
        <div>
          <h6 className="text-ui-600 mb-2">Shipping</h6>
          <div className="mb-5">
            <Address
              id={shippingAddressId}
              name={shippingName}
              address1={shippingAddress1}
              address2={shippingAddress2}
              suburb={shippingSuburb}
              state={shippingState}
              postcode={shippingPostcode}
              country={shippingCountry}
              onUpdate={onUpdate}
            />
          </div>
          <Line label="Method">{shippingMethod}</Line>
        </div>
      </div>

      <Heading text="Line Items" />

      <div className="mb-10">
        <DataTable
          headings={['SKU', 'Description', 'Qty', 'Gift', 'Subtotal']}
          rows={lineItemRows}
        />
      </div>

      <Heading text="Totals & Payments" />

      <div className="grid grid-cols-2 gap-5 mb-10">
        <div>
          <Line label="Subtotal">
            {subtotal !== undefined ? formatMoney(subtotal) : '-'}
          </Line>
          <Line label="GST">{gst !== undefined ? formatMoney(gst) : '-'}</Line>
          <Line label="Shipping">
            {shippingCost !== undefined ? formatMoney(shippingCost) : '-'}
          </Line>
          <Line label="incl. Gift Wrapping">
            {giftWrappingTotalCost > 0
              ? formatMoney(giftWrappingTotalCost)
              : '-'}
          </Line>
          <Line label="Discount">
            {discount ? (
              <>
                -
                {discount.type === 'percentage'
                  ? formatPercentage(discount.value)
                  : formatMoney(discount.value)}
                {discount.couponCode && (
                  <span className="font-mono text-sm text-ui-500">
                    {' '}
                    ({discount.couponCode})
                  </span>
                )}
              </>
            ) : (
              '-'
            )}
          </Line>
          <Line label="Total">
            {total !== undefined ? formatMoney(total) : '-'}
          </Line>
        </div>
        <div>
          <Line label="Method">{paymentMethod ?? '-'}</Line>
        </div>
      </div>

      <Heading text="Metadata" />

      <div className="grid grid-cols-2 gap-5">
        <div>
          <h6 className="text-ui-600 mb-2">Customer Notes</h6>
          <p>{customerNotes ?? '-'}</p>
        </div>
        <div>
          <h6 className="text-ui-600 mb-2">Gift Message</h6>
          {supportsGiftCard ? (
            <GiftCardForm orderId={orderId} message={giftMessage ?? ''} />
          ) : (
            <p>{giftMessage ?? '-'}</p>
          )}
        </div>
      </div>
    </SplitPanel>
  );
};
