type Props = {
  readonly value: string;
  readonly onChange: (date: string) => void;
};

const DateTime = ({ value, onChange }: Props) => {
  return (
    <input
      className="block w-full bg-white border border-ui-200 rounded px-2 py-1 focus:outline-none"
      type="date"
      value={value}
      onChange={(event) => onChange && onChange(event.target.value)}
    />
  );
};

export default DateTime;
