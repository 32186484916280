import { AxiosInstance } from 'axios';
import { QueryFunctionContext } from 'react-query';
import type { Report } from '../types';

export function getSummary(client: AxiosInstance) {
  return async ({
    queryKey: [_],
  }: QueryFunctionContext<[string]>): Promise<readonly Report[]> => {
    const { data } = await client.get<readonly Report[]>(`stats/report`);

    return data;
  };
}
