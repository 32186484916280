import { OrderStatusHandle } from '../enums';
import { Order } from '../types';

/**
 * Attempt to determine whether the input value should be interpreted as an
 * express shipping option.
 *
 * @param value The input plain text value.
 */
export function isExpressShipping(value: string): boolean {
  return value.toLowerCase().trim().indexOf('express') >= 0;
}

/**
 * Whether or not the target order should be updated to printed.
 *
 * @param order The target order.
 */
export function shouldUpdateToPrinted(order: Order): boolean {
  const persistentStatuses: readonly string[] = [
    OrderStatusHandle.Shipped,
    OrderStatusHandle.Cancelled,
    OrderStatusHandle.Refunded,
    OrderStatusHandle.Invoiced,
    OrderStatusHandle.Printed,
  ];

  return !(
    order.statusChanges.length > 0 &&
    persistentStatuses.includes(order.statusChanges[0].status.handle)
  );
}
