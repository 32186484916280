import classNames from 'classnames';
import { ReactNode } from 'react';

export type Props = {
  readonly headings?: readonly ReactNode[];
  readonly rightAlignLast?: boolean;
  readonly squashFirst?: boolean;
  readonly rows: readonly {
    readonly key: string | number;
    readonly nested?: boolean;
    readonly values: readonly ReactNode[];
  }[];
};

const headingTheme = (rightAlignLast: boolean) =>
  classNames('py-2 pr-5 text-left last:pr-0', {
    'last:text-right': rightAlignLast,
  });

const bodyTheme = (rightAlignLast: boolean, nested?: boolean) =>
  classNames('pr-5 last:pr-0', {
    'last:text-right': rightAlignLast,
    'py-2': !nested,
    'py-1': nested,
    'first:pl-4 last:pr-4': nested,
  });

const rowTheme = (nested?: boolean) =>
  classNames('border-t border-b border-ui-200', {
    'bg-ui-100': nested,
  });

const DataTable = ({
  headings,
  rightAlignLast = true,
  squashFirst = true,
  rows,
}: Props) => {
  return (
    <table className="w-full whitespace-nowrap">
      {headings && (
        <thead>
          <tr className="text-sm text-ui-500">
            {headings.map((heading, i) => (
              <th key={i} className={headingTheme(rightAlignLast)}>
                {heading}
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {rows.map((row) => (
          <tr key={row.key} className={rowTheme(row.nested)}>
            {row.values.map((value, i) => (
              <td
                key={i}
                valign="top"
                width={squashFirst && i === 0 ? 1 : undefined}
                className={bodyTheme(rightAlignLast, row.nested)}
              >
                {value}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DataTable;
