import { AxiosInstance } from 'axios';

type Variables = {
  readonly id: number;
  readonly body: string;
};

export function addNote(client: AxiosInstance) {
  return async (variables: Variables) => {
    await client.post(`orders/${variables.id}/notes`, {
      body: variables.body,
    });
  };
}
